import React, { useState } from "react";
import PopupTextField from "../ui-el/PopupTextField";
import PopupSelectField from "../ui-el/PopupSelectField";
import PopupBox from "../PopupBox";
import TabOpener from "../ui-el/TabOpener";
import { checkValidity, onDataChange2, onSelectData2, uniqueIdBuilder } from "../../util/Dynamics";
import { ImuApi } from "../../Apis/ImuApi";

const DISABLED_TEXT_BY_PROPERTY_TYPE = {
    "0 - Abitazione principale e assimilate (Cat. da A2 ad A7) e pertinenze (C/2, C/6, C/7)": "",
    "1 - Abitazione principale di pregio (Cat. A/1, A/8, A/9) e pertinenze (C/2, C/6, C/7)": "",
    "24 - Abitazione concessa in comodato gratuito a condizioni di Legge (riduzione 50% base imponibile)": "",
    "2 - Abitazioni assegnate dagli Istituti Autonomi Case Popolari (ex IACP/ARES/ALER)": "",
    "25 - Immobili locati a canone concordato (riduzione al 75%)": "",
    "3 - Altre abitazioni - immobili Cat. A (tranne A/10)": "",
    "4 - Cat. A/10 - Uffici e studi privati": "Non richiesto per questa categoria e tipologia di immobile",
    "5 - Cat. C/1 - Negozi e botteghe": "",
    "6 - Cat. C/2 - Magazzini e locali di deposito": "",
    "7 - Cat. C/3 - Laboratori per arti e mestieri": "",
    "8 - Cat. B, C/4, C/5 - Fabbricati comuni": "",
    "9 - Cat. C/6, C/7 - Stalle, scuderie, rimesse, autorimesse - Tettoie": "",
    "10 - Cat. D, tranne D/5 e D/10 - Immobili industriali e commerciali": "",
    "11 - Cat. D/5 - Istituti di credito ed assicurazioni": "",
    "12 - Fabbricati rurali ad uso strumentale allattivita agricola (D/10)": "",
    "13 - Fabbricati rurali ad uso strumentale allattivita agricola (Cat. A, C/2, C/6, C/7)": "",
    "20 - Beni Merce - Realizzati da Imprese Edili, destinati alla vendita e rimasti invenduti": "",
    "14 - Aree fabbricabili": "",
    "15 - Terreni": "",
    "16 - Terreni agricoli": "",
    "17 - Terreni agricoli posseduti e condotti da operatori iscritti a previdenza agricola": "",
    "1178 - Terreni agricoli in categoria OR.IR.FI. e ROSETO esclusi i coltivatori diretti o imprenditori agricoli professionali iscritti alla previdenza agricola": ""
}
function AddPropertyPopup(props) {
    const [_, setSelection] = useState("");
    // console.log("props", props);
    const [propertyData, setPropertyData] = useState({
        dati_immobile: {
            anno: props.year || "",
            tipologia_immobile: "",
            catastale: "",
            rendita_catastale: "",
            percentuale_di_possesso: "",
            numero_conviventi: "",
            immobile_variato: "",
            mese_inizio: "",
            mese_fine: "",
        },
        dati_comunali: {
            aliquota_acconto: "",
            aliquota_saldo: "",
            immobile_esente: "",
            immobile_storico_inagibile: "",
            riduzione_pensionato_estero: "",
            immobile_presente: "",
            comune_montano: ""
        },
        pertinenza_1: {
            rendita_catastale: "",
            percentuale_di_possesso: "",
            mese_inizio: "",
            mese_fine: "",
            immobile_storico_inagibile: ""
        },
        pertinenza_2: {
            rendita_catastale: "",
            percentuale_di_possesso: "",
            mese_inizio: "",
            mese_fine: "",
            immobile_storico_inagibile: ""
        },
        pertinenza_3: {
            rendita_catastale: "",
            percentuale_di_possesso: "",
            mese_inizio: "",
            mese_fine: "",
            immobile_storico_inagibile: ""
        },
        id: uniqueIdBuilder()
    });
    const [error, setError] = useState(false);

    const [imuConfig, setImuConfig] = useState({
        aliquota_acconto: "",
        aliquota_saldo: "",
        comune_montano: ""
    })

    // console.log("add property popup", props);

    const dynamicTitleFieldHandler = () => {
        let type = propertyData.dati_immobile.tipologia_immobile;

        if (type.includes("14"))
            return "Valore mercato superficie"

        if (type.includes("15")
            || type.includes("16")
            || type.includes("17")
            || type.includes("1178"))
            return "Reddito dominicale";

        return "Rendita catastale";
    }

    const dynamicOptionsdHandler = () => {
        let type = propertyData.dati_immobile.tipologia_immobile;

        for (let i of ["14", "15", "16", "17", "20", "1178"]) {
            if (type.includes(i))
                return [];
        }

        if (type.includes("24"))
            return ["no", "storico", "comodato 50% immobile", "storico + comodato 50%"];

        return ["no", "storico", "inagibile"];
    }

    const save = () => {
        const checkObj = {
            dati_immobile: {
                anno: propertyData.dati_immobile?.anno,
                tipologia_immobile: propertyData.dati_immobile.tipologia_immobile,
                rendita_catastale: propertyData.dati_immobile.rendita_catastale,
                percentuale_di_possesso: propertyData.dati_immobile.percentuale_di_possesso,
                numero_conviventi: propertyData.dati_immobile.numero_conviventi,
                immobile_variato: propertyData.dati_immobile.immobile_variato,
                mese_inizio: propertyData.dati_immobile.mese_inizio,
                mese_fine: propertyData.dati_immobile.mese_fine,
            },
            dati_comunali: {
                immobile_esente: propertyData.dati_comunali.immobile_esente,
                immobile_presente: propertyData.dati_comunali.immobile_presente,
                comune_montano: propertyData.dati_comunali.comune_montano
            }
        };

        if (checkValidity(checkObj)) {
            props.close();
            props.update({
                id: "immobili",
                data: { ...propertyData }
            });
        }
        else
            setError(true);

    }

    const realtyFilter = () => {
        const id = propertyData.dati_immobile.tipologia_immobile.split("-")[0].trim();

        for (let i of ["0", "1", "24", "25"]) {
            if (id === i) {
                return (
                    <>
                        <TabOpener title="Pertinenza C/2">
                            <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                                defaultValue={propertyData.pertinenza_1.rendita_catastale} itr="pertinenza_1"
                                id="rendita_catastale" fieldType="number" fieldTitle="Rendita catastale" />
                            <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                                defaultValue={propertyData.pertinenza_1.percentuale_di_possesso} itr="pertinenza_1"
                                id="Percentuale di possesso" fieldType="number" fieldTitle="Percentuale possesso" max={"100"} min="0" />
                            <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)} itr="pertinenza_1"
                                defaultValue={propertyData.pertinenza_1.mese_inizio} id="mese_inizio"
                                fieldType="date" fieldTitle="Mese inizio" />
                            <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                                defaultValue={propertyData.pertinenza_1.mese_fine} id="mese_fine" itr="pertinenza_1"
                                fieldType="date" fieldTitle="Mese fine" />
                            <PopupSelectField onChange={(e) => { onSelectData2(e, "pertinenza_1", setPropertyData, setSelection) }}
                                defaultValue={propertyData.pertinenza_1.immobile_storico_inagibile}
                                id="immobile_storico_inagibile" fieldTitle={"Immobile storico inagibile"} options={
                                    [
                                        "storico",
                                        "inagibile",
                                        "no"
                                    ]
                                } />
                        </TabOpener>
                        <TabOpener title="Pertinenza C/6">
                            <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                                defaultValue={propertyData.pertinenza_2.rendita_catastale} itr="pertinenza_2"
                                id="rendita catastale" fieldType="number" fieldTitle="Rendita catastale" />
                            <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                                defaultValue={propertyData.pertinenza_2.percentuale_di_possesso} itr="pertinenza_2"
                                id="Percentuale Di possesso" fieldType="number" fieldTitle="Percentuale possesso" max={"100"} min="0"/>
                            <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                                defaultValue={propertyData.pertinenza_2.mese_inizio} itr="pertinenza_2"
                                id="mese_inizio" fieldType="date" fieldTitle="Mese inizio" />
                            <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                                defaultValue={propertyData.pertinenza_2.mese_fine} itr="pertinenza_2"
                                id="mese_fine" fieldType="date" fieldTitle="Mese fine" />
                            <PopupSelectField onChange={(e) => { onSelectData2(e, "pertinenza_2", setPropertyData, setSelection) }}
                                defaultValue={propertyData.pertinenza_2.immobile_storico_inagibile}
                                id="immobile_storico_inagibile" fieldTitle={"Immobile storico inagibile"} options={
                                    [
                                        "storico",
                                        "inagibile",
                                        "no"
                                    ]
                                } />
                        </TabOpener>
                        <TabOpener title="Pertinenza C/7">
                            <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                                defaultValue={propertyData.pertinenza_3.rendita_catastale} itr="pertinenza_3"
                                id="Rendita_catastale" fieldType="number" fieldTitle="Rendita catastale" />
                            <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                                defaultValue={propertyData.pertinenza_3.percentuale_di_possesso} itr="pertinenza_3"
                                id="Percentuale di Possesso" fieldType="number" fieldTitle="Percentuale possesso" max={"100"} min="0"/>
                            <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                                defaultValue={propertyData.pertinenza_3.mese_inizio} itr="pertinenza_3"
                                id="mese_inizio" fieldType="date" fieldTitle="Mese inizio" />
                            <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                                defaultValue={propertyData.pertinenza_3.mese_fine} itr="pertinenza_3"
                                id="mese_fine" fieldType="date" fieldTitle="Mese fine" />
                            <PopupSelectField onChange={(e) => { onSelectData2(e, "pertinenza_3", setPropertyData, setSelection) }}
                                defaultValue={propertyData.pertinenza_3.immobile_storico_inagibile}
                                id="immobile_storico_inagibile" fieldTitle={"Immobile storico inagibile"} options={
                                    [
                                        "storico",
                                        "inagibile",
                                        "no"
                                    ]
                                } />
                        </TabOpener>
                    </>
                )
            }
        }
    }

    const getData = async (type, year) => {
        try {
            const res = await ImuApi.getImuConfig(type, year);

            if (!res.success)
                throw new Error(res.message);

            setPropertyData(prev => {
                prev.dati_comunali.aliquota_acconto = res.data?.aliquota_acconto !== 0 ? res.data?.aliquota_acconto : "Esente IMU";
                prev.dati_comunali.aliquota_saldo = res.data?.aliquota_saldo !== 0 ? res.data?.aliquota_saldo : "Esente IMU";
                prev.dati_comunali.comune_montano = res.data?.comune_montano === 0 ? "no" : (res.data?.comune_montano === 1 ? "si" : "")
                return { ...prev };
            })
            setImuConfig(res.data);
        } catch (err) {
            console.log(err);
            setPropertyData(prev => {
                prev.dati_comunali.comune_montano = "no"
                return { ...prev };
            })
        }
    }

    const getDataOnBlur = (e, value) => {
        // console.log("////////////////// add property", type)
        
        const type = value || e.target.options[e.target.selectedIndex].value;
        getData(type.split(" ")[0], propertyData?.dati_immobile?.anno);
    }

    const getDisabledText = (propertyType) => {
        
    }

    return (
        <PopupBox close={props.close} save={save} title="Immobile">
            {
                error &&
                <p style={{ color: "red", fontWeight: "bolder" }}>Completa tutti i campi</p>
            }

            <TabOpener title={"Dati Immobile"}>
                <PopupSelectField
                    onChange={(e) => {
                        onSelectData2(e, "dati_immobile",
                            setPropertyData, setSelection)
                    }}
                    defaultValue={propertyData.dati_immobile.tipologia_immobile}
                    id="tipologia_immobile"
                    fieldTitle={"Categoria e tipologia immobile"}
                    description="Selezionare l'anno per poter scegliere la tipologia e proseguire con la richiesta"
                    onBlur={getDataOnBlur}
                    options={
                        [
                            "0 - Abitazione principale e assimilate (Cat. da A2 ad A7) e pertinenze (C/2, C/6, C/7)",
                            "1 - Abitazione principale di pregio (Cat. A/1, A/8, A/9) e pertinenze (C/2, C/6, C/7)",
                            "24 - Abitazione concessa in comodato gratuito a condizioni di Legge (riduzione 50% base imponibile)",
                            "2 - Abitazioni assegnate dagli Istituti Autonomi Case Popolari (ex IACP/ARES/ALER)",
                            "25 - Immobili locati a canone concordato (riduzione al 75%)",
                            "3 - Altre abitazioni - immobili Cat. A (tranne A/10)",
                            "4 - Cat. A/10 - Uffici e studi privati",
                            "5 - Cat. C/1 - Negozi e botteghe",
                            "6 - Cat. C/2 - Magazzini e locali di deposito",
                            "7 - Cat. C/3 - Laboratori per arti e mestieri",
                            "8 - Cat. B, C/4, C/5 - Fabbricati comuni",
                            "9 - Cat. C/6, C/7 - Stalle, scuderie, rimesse, autorimesse - Tettoie",
                            "10 - Cat. D, tranne D/5 e D/10 - Immobili industriali e commerciali",
                            "11 - Cat. D/5 - Istituti di credito ed assicurazioni",
                            "12 - Fabbricati rurali ad uso strumentale allattivita agricola (D/10)",
                            "13 - Fabbricati rurali ad uso strumentale allattivita agricola (Cat. A, C/2, C/6, C/7)",
                            "20 - Beni Merce - Realizzati da Imprese Edili, destinati alla vendita e rimasti invenduti",
                            "14 - Aree fabbricabili",
                            "15 - Terreni",
                            "16 - Terreni agricoli",
                            "17 - Terreni agricoli posseduti e condotti da operatori iscritti a previdenza agricola",
                            "1178 - Terreni agricoli in categoria OR.IR.FI. e ROSETO esclusi i coltivatori diretti o imprenditori agricoli professionali iscritti alla previdenza agricola"
                        ]
                    }
                    disabled={!propertyData?.dati_immobile?.anno}
                />
                <PopupSelectField
                    onChange={(e) => { onSelectData2(e, "dati_immobile", setPropertyData, setSelection) }}
                    id="catastale"
                    fieldTitle={"Categoria catastale"}
                    defaultValue={propertyData.dati_immobile.catastale}
                    description={propertyData.dati_immobile.tipologia_immobile.includes("20") ? "" : " "}
                    disabled={propertyData.dati_immobile.tipologia_immobile.includes("20") ? false : true}
                    disabledText={DISABLED_TEXT_BY_PROPERTY_TYPE?.[propertyData.dati_immobile.tipologia_immobile]}
                    options={
                        [
                            "3 - Altre abitazioni - immobili Cat. A (tranne A/10)",
                            "4 - Cat. A/10 - Uffici e studi privati",
                            "5 - Cat. C/1 - Negozi e botteghe",
                            "6 - Cat. C/2 - Magazzini e locali di deposito",
                            "7 - Cat. C/3 - Laboratori per arti e mestieri",
                            "8 - Cat. B, C/4, C/5 - Fabbricati comuni",
                            "9 -  Cat. C/6, C/7 - Stalle, scuderie, rimesse, autorimesse - Tettoie",
                            "10 -  Cat. D, tranne D/5 e D/10 - Immobili industriali e commerciali",
                            "11 - Cat. D/5 - Istituti di credito ed assicurazioni",
                        ]
                    } />
                <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                    defaultValue={propertyData.dati_immobile.numero_conviventi} id="numero_conviventi"
                    fieldType="number" fieldTitle="Altri proprietari residenti*"
                    description={propertyData.dati_immobile.numero_conviventi.includes("1") ? " " : ""}
                // disabled={propertyData.dati_immobile.numero_conviventi.includes("1") ? true : false}
                />
                <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                    fieldType="number" fieldTitle={`${dynamicTitleFieldHandler()}*`}
                    id={`rendita_catastale`} itr="dati_immobile" />
                <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                    defaultValue={propertyData.dati_immobile.percentuale_di_possesso} id="percentuale_di_possesso"
                    fieldType="number" fieldTitle="Percentuale possesso*" itr="dati_immobile" max={"100"} min="0"/>
                <PopupSelectField onChange={(e) => { onSelectData2(e, "dati_immobile", setPropertyData, setSelection) }}
                    id="immobile_variato"
                    fieldTitle={"Immobile variato"}
                    defaultValue={propertyData.dati_immobile.immobile_variato}
                    description="Da spuntare nel caso in cui siano intervenute delle variazioni per l’immobile che richiedono la presentazione della Dichiarazione di variazione IMU."
                    options={
                        [
                            "si",
                            "no"
                        ]
                    } />
                <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                    defaultValue={propertyData.dati_immobile.mese_inizio} id="mese_inizio"
                    fieldType="date" fieldTitle="Mese inizio possesso*" itr="dati_immobile" />
                <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                    defaultValue={propertyData.dati_immobile.mese_fine} id="mese_fine"
                    fieldType="date" fieldTitle="Mese fine possesso*" itr="dati_immobile" />
            </TabOpener>
            <TabOpener title={"Dati Comunali"}>
                <PopupTextField
                    defaultValue={imuConfig?.aliquota_acconto !== "" ? imuConfig?.aliquota_acconto !== 0 ? imuConfig?.aliquota_acconto : "Esente IMU" : "Aliquota non calcolabile"}
                    fieldType="text"
                    fieldTitle="Aliquota IMU acconto*"
                    disabled={true}
                    id="aliquota_acconto"
                    description="Selezionare la tipologia immobile per calcolare l'aliquota" />
                <PopupTextField
                    defaultValue={imuConfig?.aliquota_acconto !== "" ? imuConfig?.aliquota_saldo !== 0 ? imuConfig?.aliquota_saldo : "Esente IMU" : "Aliquota non calcolabile"}
                    fieldType="text"
                    fieldTitle="Aliquota IMU saldo*"
                    id="aliquota_saldo"
                    disabled={true}
                    description="Selezionare la tipologia immobile per calcolare l'aliquota" />

                

                <>
                    {imuConfig?.comune_montano === 0 &&
                        <PopupSelectField onChange={(e) => { onSelectData2(e, "dati_comunali", setPropertyData, setSelection) }}
                        defaultValue={propertyData.dati_comunali.comune_montano} disabled id="comune_montano" fieldTitle={"Comune montano"}
                        options={["no"]} />
                    }
                    {imuConfig?.comune_montano === 1 &&
                        <PopupSelectField onChange={(e) => { onSelectData2(e, "dati_comunali", setPropertyData, setSelection) }}
                        defaultValue={propertyData.dati_comunali.comune_montano} disabled id="comune_montano" fieldTitle={"Comune montano"}
                        options={["si"]} />
                    }
                    {imuConfig?.comune_montano === 2 &&
                        <PopupSelectField onChange={(e) => { onSelectData2(e, "dati_comunali", setPropertyData, setSelection) }}
                        defaultValue={propertyData.dati_comunali.comune_montano} id="comune_montano" fieldTitle={"Comune montano"}
                        options={["si", "no"]} />
                    }
                </>

                <PopupSelectField onChange={(e) => { onSelectData2(e, "dati_comunali", setPropertyData, setSelection) }}
                    defaultValue={propertyData.dati_comunali.immobile_esente} id="immobile_esente" fieldTitle={"Immobile esente"}
                    description="Sono considerati esenti dal pagamento dell'IMU gli immobili non utilizzabili né disponibili, per i quali sia stata presentata denuncia. L'esenzione è limitata al periodo dell'anno durante il quale si verifica l'occupazione/non disponibilità dell'immobile. È possibile utilizzare questa casella anche per eventuali altre esenzioni previste se compatibili (es. immobili in zone colpite da terremoto, etc.)."

                    options={
                        [
                            "Esente",
                            "Non esente"
                        ]
                    } />
                <PopupSelectField onChange={(e) => { onSelectData2(e, "dati_comunali", setPropertyData, setSelection) }}
                    defaultValue={propertyData.dati_comunali.immobile_storico_inagibile} id="immobile_storico_inagibile"
                    fieldTitle={"Immobile storico o inagibile"} options={dynamicOptionsdHandler()} disabled={dynamicOptionsdHandler().length === 0 ? true : false} />
                <PopupSelectField onChange={(e) => { onSelectData2(e, "dati_comunali", setPropertyData, setSelection) }}
                    defaultValue={propertyData.dati_comunali.riduzione_pensionato_estero} id="riduzione_pensionato_estero"
                    description={propertyData.dati_immobile.tipologia_immobile.includes("3") ? "" : " "}
                    fieldTitle={"Riduzione pensionato estero in convenzione internazionale"}
                    disabled={propertyData.dati_immobile.tipologia_immobile.includes("3") ? false : true}
                    options={
                        propertyData.dati_immobile.tipologia_immobile.includes("3") ?
                            [
                                "si",
                                "no"
                            ] :
                            []
                    } />

                <PopupSelectField onChange={(e) => { onSelectData2(e, "dati_comunali", setPropertyData, setSelection) }}
                    id="immobile_presente" fieldTitle={"Immobile giá presente"} defaultValue={propertyData.dati_comunali.immobile_presente}
                    description={"Un immobile deve essere considerato già presente solo se precedentemente inserito nell'arco dell'anno in corso per meno di 12 mesi. Se per un immobile si genera l'F24 per 12 mesi non può essere “già presente”. Questa casella, quando spuntata, permette di evitare che un immobile sia inserito nell’F24 due volte."}
                    options={
                        [
                            "presente",
                            "non presente"
                        ]
                    } />
            </TabOpener>

            {
                realtyFilter()
            }
        </PopupBox>
    )
}

export default AddPropertyPopup;








