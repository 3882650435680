import React, { useEffect, useState } from "react";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";
import SelectionFormBox from "../../../UI-component/SelectionFormBox";
import { useService } from "../../../classes/useService";
import { TaxBreaksService } from "../../../classes/taxBreaks/TaxBreakService";

function General(props) {
  // console.log("Props", props?.info?.data)
  const {data, values, loading, error, isValidStep, save, update, savePrefs} = useService(TaxBreaksService, props.save, props?.info?.data || {});

  const onNext = () => {
    savePrefs();
    props.next();
  }

  useEffect(() => {
    savePrefs();
  }, [data])

  // console.log("general error", error);
  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Tipologia", id: "#report-author" },
        ]} />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            <ErrorAlert errors={[{ name: "Tipologia", id: "#isee-info" }]} err={error.tipologia} />

            <SelectionFormBox
              showTitle={`Tipologia*`}
              title={"tipologia"}
              description={" "}
              choices={values.types}
              defaultValue={data?.tipologia}
              classDefaultValue={""}
              oKey={""}
              update={update}
              disabled={"  " === "" ? "disabled" : ""} />

          </div>
        </div>
        <Loader loading={loading} fixedPosition/>

        <NextBtnForm
          next={onNext}
          saveRequest={save}
          back={props.back}
          disabled={!isValidStep("general")} />
        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  )
}

export default General;