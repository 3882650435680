import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import { getTenantCode } from "../../../util";

const DEFAULT_NOBONUS_VALUE = "Nessun bonus attivo al momento";

const VALUES_BY_TENANT = {
    "default": {
        extra_fields: [
            {
                id: "marca_bollo_1",
                defaultValue: {}
            },
            {
                id: "marca_bollo_2",
                defaultValue: {}
            },
            {
                id: "tipo_matrimonio",
                defaultValue: ""
            },
            {
                id: "data_matrimonio",
                defaultValue: ""
            },
        ]
    }
}

const getValues = () => {
    const tenantCode = getTenantCode();
    return VALUES_BY_TENANT[tenantCode] || VALUES_BY_TENANT["default"]
}

const getExtraFields = (context) => {
    const tenantCode = getTenantCode();
    const values = VALUES_BY_TENANT[tenantCode] || VALUES_BY_TENANT["default"];
    if(context && values?.extra_fields){
        let fieldsObj = {}
        values?.extra_fields?.map(field => {
            fieldsObj[field?.id] = context?.[field?.id] || field?.defaultValue || null;
        })
        // console.log("fieldsObj", fieldsObj)
        return fieldsObj;
    }

    return {}
}

export const useMarriage = (parentSaveFunc, config, parentSendFunc) => {
    /********************* hooks go down here *********************/
    const ctx = useSelector(state => state.stepSlice.data);
    // console.log("ctx", ctx)
    const ctxMarriage = useSelector(state => state.stepSlice.data.matrimonio);
    const ctxApplicant = useSelector(state => state.stepSlice.data.matrimonio.richiedente);
    const ctxApplicant2 = useSelector(state => state.stepSlice.data.matrimonio.richiedente_2);
    const ctxPrefs = useSelector(state => state.stepSlice.data.preferenze?.matrimonio);
    const isStopped = useSelector(state => state.stepSlice.data.stopped);
    const dispatcher = useDispatch();

    // console.log("ctxPrefs", ctxPrefs);
    /********************* state go down here *********************/
    const [data, setData] = useState({
        richiedente: ctxApplicant?.length > 0 ? [{ ...ctxApplicant[0], selected: true }] : [],
        richiedente_2: ctxApplicant2?.length > 0 ? [{ ...ctxApplicant2[0], selected: true }] : [],
        ...getExtraFields(ctxPrefs)
    });
    const [values, setValues] = useState({
        ...getValues()
    })
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    /********************* methods go down here *********************/
    ////////////////////// get functions //////////////////////

    const getFields = () => {
        return ["richiedente", "richiedente_2", "marca_bollo_1", "marca_bollo_2", "tipo_matrimonio", "data_matrimonio", "preferenze"]
    }

    ////////////////////// validate functions //////////////////////
    const isValidField = (id, isSendRequest) => {
        switch(id){
            case "richiedente":
            case "richiedente_2":
                return data[id]?.length > 0 ? true : false;
            case "preferenze":
                return true
            case "tipo_matrimonio":
            case "data_matrimonio":
                return data?.[id] ? true : false
            case "marca_bollo_1":
                return (
                    data?.marca_bollo_1?.numero?.length === 14 && 
                    data?.marca_bollo_1?.data) ? true : false
            case "marca_bollo_2":
                return (
                    data?.marca_bollo_2?.numero?.length === 14 && 
                    data?.marca_bollo_2?.data) ? true : false
            default:
                return data[id] ? true : false
        }
    }

    const checkFields = (fields) => {
        // console.log("checkFields", fields)
        if(fields?.length > 0){
            let isValid = true;
            for(let key in fields){
                isValid = typeof isValidField(fields[key]) !== "object" && isValidField(fields[key]) === true;
                // console.log("field", data, fields[key], isValid)
                if(!isValid) break;
            }
            return isValid;
        }

        return false;
    }

    const isValidData = (isSendRequest) => {
        const tenantCode = getTenantCode();
        let requiredFields = [];
        // console.log("is valid data", isSendRequest)
        switch(tenantCode){
            default:
                if(isSendRequest) requiredFields = getFields()
                else requiredFields = []
        }

        if(requiredFields?.length > 0) return checkFields(requiredFields)        

        return true;
    }

    const checkErrors = () => {
        let newError = {...error}

        const fields = getFields() || []
        fields.map(field => {
            let isValid = isValidField(field, true)
            newError[field] = typeof isValid === "object" ? isValid : !isValid
        })
        
        setError(newError);
    }

    const isValidStep = (stepId) => {
        // console.log("data", data);
        const tenantCode = getTenantCode();
        switch(stepId){
            case "general":
                return (error?.richiedente || error?.richiedente_2) ? false : true
            case "specific":
            case "summary":
                return (error?.richiedente
                    || error?.tipo_matrimonio
                    || error?.data_matrimonio
                    || error?.marca_bollo_1
                    || error?.marca_bollo_2
                    ) ? false : true
            default:
                return false;
        }
    }

    const getDefaultPrefs = () => {
        return{
            tipo_matrimonio: data?.tipo_matrimonio || "",
            data_matrimonio: data?.data_matrimonio || "",
            marca_bollo_1: data?.marca_bollo_1 || {},
            marca_bollo_2: data?.marca_bollo_2 || {},
        }
    }

    const getPrefs = (isSend) => {
        const tenantCode = getTenantCode();
        switch(tenantCode){
            // case "L337":
            //     const shouldShowMarca = values?.extra_declarations ? data?.chiede_extra === values?.extra_declarations?.[3]?.value : false;
            //     return {
            //         ...getDefaultPrefs(),
            //         //// CHIEDE extra ////
            //         chiede_extra: data?.chiede_extra || "",
            //         //// PEC ////
            //         ...((data?.modalita === "Invio tramite PEC" || !isSend) && 
            //         {indirizzo_pec: data?.indirizzo_pec || "",}),
            //         //// MARCA ////
            //         ...((shouldShowMarca || !isSend) && {marca_bollo: data?.marca_bollo || {}}),
            //     }
            default:
                return {
                    ...getDefaultPrefs(),
                }
        }
    }
    ////////////////////// UPDATE //////////////////////
    const parseField = (id, newData) => {
        const fieldId = id?.toLowerCase()?.replace(" ", "_");
        switch(fieldId){
            case "richiedente":
            case "richiedente_1":
            case "richiedente_2":
                return newData?.removing ? [] : [{ ...newData?.data?.[0], selected: true }]
            default:
                return newData?.data || null
        }
    }

    const update = (newData) => {
        // console.log("useMarriage update", newData, typeof newData === "object", Array.isArray(newData));
        if(typeof newData === "object" && !Array.isArray(newData)){
            const id = newData?.id?.toLowerCase()?.replace(" ", "_");
            const internalId = newData?.internalId || null;
            if(id){
                if(internalId){
                    setData(oldData => ({
                        ...oldData,
                        [id]: {
                            ...(data?.[id] || {}),
                            [internalId]: newData?.data || null
                        }
                    }))
                }
                else{
                    setData(oldData => ({
                        ...oldData,
                        [id]: parseField(id, newData)
                    }))
                }
            }
        }
        else if (Array.isArray(newData)){
            let updatedData = {...data};
            newData.map(newDataItem => {
                const id = newDataItem?.id?.toLowerCase()?.replace(" ", "_");
                const internalId = newDataItem?.internalId || null;
                if(id){
                    if(internalId){
                        updatedData = {
                            ...updatedData,
                            [id]: {
                                ...(updatedData?.[id] || {}),
                                [internalId]: newDataItem?.data || null
                            }
                        }
                    }
                    else{
                        updatedData = {
                            ...updatedData,
                            [id]: parseField(id, newDataItem)
                        }
                    }
                }
            })
            // console.log("updatedData", updatedData)
            setData(updatedData);
        }
    }

    const updateField = (e, fieldId, isInternal, customId) => {
        // console.log("e", e?.target?.id, e?.target?.value);
        const id =(customId || e?.id || e?.target?.id)?.toLowerCase();
        const value = e?.data || e?.target?.value;
        // console.log("updateField", id, value, (fieldId && id && isInternal) || (fieldId && !isInternal));
        if((fieldId && id && isInternal) || (fieldId && !isInternal) || (id && value)){
            update({
                id: isInternal ? fieldId : id,
                internalId: isInternal ? id : null,
                data: value
            })
        }
    }

    const updatePrefs = (newPreferences) => {
        // console.log("newPreferences", newPreferences)
        // return;
        const prefsFields = Object.keys(getPrefs());
        if(prefsFields?.length > 0 && newPreferences?.data?.matrimonio){
            let updatedPrefs = {};
            Object.keys(newPreferences?.data?.matrimonio)?.map(key => {
                if(prefsFields?.indexOf(key) !== -1)
                    updatedPrefs[key] = newPreferences?.data?.matrimonio[key];
            })
            // console.log("new preferences", newPreferences)
            setData({
                ...data,
                ...updatedPrefs
            })
        }
    }
    ////////////////////// SAVE //////////////////////
    const savePrefs = () => {
        dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: "matrimonio", data: {
            ...getPrefs()
        } }));
    }

    const save = async (isSendRequest) => {
        const fields = getFields() || []
        let saveData = {}
        // console.log("data", data);
        fields.map(field => {
            let isValid = typeof isValidField(field, isSendRequest) !== "object" && isValidField(field) === true;
            // console.log("is valid data", field, isValid, data[field])
            if(data[field] && isValid) saveData[field] = data[field];
        })

        // console.log("saveData", saveData);


        const prefs = getPrefs(true)
        if(Object.keys(prefs)?.length > 0){
            saveData.preferenze = {
                matrimonio: {...prefs}
            }
        }

        // console.log("fields", saveData)
        let isSend = typeof isSendRequest === "boolean" ? isSendRequest : false;
        // console.log("save data", saveData, isValidData(isSend))
        if(isValidData(isSend) && ((parentSaveFunc && !isSend) || (parentSendFunc && isSend))){
            dispatcher(stepSlice.actions.dynamicSave({ id: "Marriage", data: {...saveData} }));
            savePrefs();
            setLoading(true);
            if(isSend){
                await parentSendFunc(saveData);
            }
            else{
                await parentSaveFunc(saveData);
            }
            setLoading(false);
        }
    }

    const clear = () => {
        setData({})
    }

    /********************* useEffects go down here *********************/

    useEffect(() => {
        // console.log("useMarriage", data);
        savePrefs();
        dispatcher(stepSlice.actions.dynamicSave({ id: "Marriage", data: data || {} }));
        checkErrors();
    }, [data]);

    useEffect(() => {
        // console.log("useMarriage error", error);
    }, [error])

    useEffect(() => {
        checkErrors();
    }, [])

    return {
        data,        // current data of the service request
        values,      // values to populate fields such as dropdowns
        loading,     // true = is saving request 
        error,       // error flag by data[id] eg. data["richiedente"] is invalid -> error.richiedente = true
        save,        // function to save the current service request
        savePrefs,   // function to save prefs into the store
        update,      // function to update the data object
        updateField,
        updatePrefs,
        isValidStep, // function to check <stepId> data -> useful for nextButton disable status
    }
}