import { useEffect, useState } from "react";

import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import WorkPath from "../../UI-component/WorkPath";
import CompactInfo from "../../UI-component/CompactInfo";
import SideList from "../../UI-component/SideList";
import TabHeader from "../../UI-component/TabHeader";
import ReminderTable from "../../UI-component/ReminderTable";
import CompletitionBox from "../../UI-component/CompletitionBox";
import HelpBox from "../../UI-component/HelpBopx";
import CompletitionBoxMsg from "../../UI-component/CompletitionBoxMsg";
import { MessageApi } from "../../Apis/MessageApi";
import { PaymentApi } from "../../Apis/PaymentApi";
import { ServiceRequestAPI } from "../../Apis/ServiceRequestAPI";
import { uniqueIdBuilder } from "../../util/Dynamics";
import { DashboardApi } from "../../Apis/DashboardApi";
import { getServiceDays } from "../../UI-component/service/utils";


export default function AreaPersonale(props) {
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const [current, setCurrent] = useState("");
  const [fiscal_code, setFiscalCcode] = useState("");
  const [tab, setTab] = useState("");
  const [messages, setMessages] = useState([]);
  const [payments, setPayments] = useState([]);
  const [activities, setActivities] = useState([]);
  const [dashboard, setDashboard] = useState({
    messages: [],
    docs: []
  });

  const [services, setServices] = useState({});
  const token = localStorage.getItem("access_token");

  const tabSetter = (data) => {
    setTab(data);
  }

  const getMessages = async () => {
    try {
      const messages = await MessageApi.getMessages();

      if (!messages.success) {
        //TODO ERROR MESSAGE
      }
      setMessages(messages.data);
    } catch (err) {
      //TODO ERROR MESSAGE
      console.log(err);

      // if(err.request.status === 401) 
      //   window.location.reload();
    }
  }

  const getPayments = async () => {
    try {
      const payments = await PaymentApi.getPayments();

      if (!payments.success) {
        //TODO ERROR MESSAGE
      }
      setPayments(payments.data);
    } catch (err) {
      //TODO ERROR MESSAGE
      console.log(err);
    }
  }

  
  const checkAllPaymentsStatus = async () => {
    try {
      const paymentsStatus = await PaymentApi.checkAllPaymentStatus();

      if (!paymentsStatus.success) {
        //TODO ERROR MESSAGE
      }
    } catch (err) {
      //TODO ERROR MESSAGE
      console.log(err);
    }
  }


  const getActivities = async () => {
    try {
      const activities = await ServiceRequestAPI.getRequestByUser();

      if (!activities.success) {
        //TODO ERROR MESSAGE
      }

      setActivities(activities.data);
    } catch (err) {
      //TODO ERROR MESSAGE
      console.log(err);
    }
  }

  const getDashboard = async () => {
    try {
      const dashboard = await DashboardApi.getData();

      if (!dashboard.success) {
        //TODO ERROR MESSAGE
      }

      setDashboard(prev => {
        return {
          ...prev,
          messages: dashboard.data.messages,
          docs: dashboard.data.practices
        }
      });
    } catch (err) {
      //TODO ERROR MESSAGE
      console.log(err);
    }
  }

  const checkServices = async () => {
    const newServices = { days: {}, date: {} }
    const checkedServices = {}
    for (let key in activities) {
      const activity = activities[key];
      const { practice_number, date } = activity;
      const practiceNumberArr = practice_number?.split("-");
      // console.log("practice_number", practice_number, practiceNumberArr)
      if (practiceNumberArr?.length > 1) {
        const serviceId = practiceNumberArr[0];
        if (!checkedServices[serviceId]) {
          // console.log("enter here checkServices", serviceId, checkedServices)
          checkedServices[serviceId] = true;
          const { date, days } = await getServiceDays(serviceId);
          // console.log("check services", serviceId, date, days)
          if (days) newServices.days[serviceId] = days;
          if (date) newServices.date[serviceId] = date;
        }
      }
    }
    // console.log("new services", newServices, newServicesDate);
    setServices(newServices);
  }

  useEffect(() => {
    (async () => {
      await checkServices();
    })();
  }, [activities])

  useEffect(() => {
    checkAllPaymentsStatus();
    getMessages();
    getPayments();
    getActivities();
    getDashboard();
  }, []);

  // console.log("payments", payments)

  const tabShower = () => {
    switch (tab) {
      case "Scrivania":
        return (
          <>
            <SideList
              intitle={"INDICE DELLA PAGINA"}
              infoList={[
                { element: "Ultimi messaggi", id: "#latest-messages" },
                { element: "Ultime pratiche", id: "#latest-activities" },
              ]} />
            <div className="col-12 col-lg-8 offset-lg-1">
              <section
                className="it-page-section mb-50 mb-lg-90"
                id="payments">
                <div className="cmp-filter">
                  <div className="filter-section">
                    <h2
                      className="cmp-filter__title title-xxlarge"
                      id="payments">
                      Ultimi pagamenti
                    </h2>
                  </div>
                </div>
                <br></br>
                <div className="cmp-accordion" style={{ maxHeight: "350px", overflow: "auto", paddingRight: "10px" }}>
                  {
                    payments.length > 0 ?
                      payments.map((itm, idx) => {
                        if (!itm.pay)
                          return <div key={idx}></div>;

                        return (
                          <CompletitionBoxMsg
                            key={uniqueIdBuilder()}
                            title={itm?.title}
                            fileN={itm?.paymentCode}
                            serviceLink={itm?.serviceLink}
                            serviceNameLink={itm?.serviceNameLink}
                            serviceId={itm?.request?.service_id}
                            date={itm?.date}
                            paymentMethod={itm?.paymentMethod}
                            amount={itm?.amount}
                            pay={itm?.pay}
                            state={itm?.state}
                            link={itm?.link}
                            id={itm?.request?.id}
                            payID={itm?.id}
                            iuv={itm?.iuv}
                            type={itm?.type}
                            email={itm?.request?.data?.richiedente?.[0]?.contatti?.email}
                          />
                        )
                      }) :
                      <>
                        <h4>Nessun pagamento presente</h4>
                        <div style={{ borderBottom: "solid 1px lightgrey" }}></div>
                      </>

                  }
                </div>
              </section>

              <section className="it-page-section mb-40 mb-lg-60" id="messages">
                <div className="cmp-accordion">
                  <div className="cmp-filter">
                    <div className="filter-section">
                      <h2 className="cmp-filter__title title-xxlarge">
                        Ultimi messaggi
                      </h2>
                    </div>
                    <br></br>
                  </div>
                  <div className="accordion" id="">
                    {
                      dashboard.messages.length > 0 ?
                        <ReminderTable
                          reminders={dashboard.messages}
                          title={"Messaggi"}
                          isDoc={false}
                          opener=""
                          type={"messages"} /> :
                        <h4>Nessun messaggio presente</h4>
                    }
                  </div>
                </div>
              </section>
              <section
                className="it-page-section mb-40 mb-lg-60"
                id="practices"
              >
                <div className="cmp-filter">
                  <div className="filter-section">
                    <h2 className="cmp-filter__title title-xxlarge">
                      Ultime pratiche
                    </h2>
                  </div>
                  <br></br>
                </div>
                <div className="cmp-accordion" style={{ maxHeight: "350px", overflow: "auto", paddingRight: "10px" }}>
                  <div className="accordion" id="">
                    {
                      dashboard?.docs.length > 0 ?
                        dashboard?.docs.map((itm, idx) => {
                          
                          return (
                            <CompletitionBox doc={
                              {
                                id: itm?.id,
                                name: itm?.name,
                                state: itm?.state,
                                date: itm?.date,
                                paymentCode: itm?.paymentCode,
                                paymentMethod: itm?.paymentMethod,
                                practiceNumber: itm?.practice_number,
                                amount: itm?.amount,
                                link: itm?.link,
                                nameLink: itm?.nameLink,
                                serviceLink: itm?.serviceLink,
                                serviceNameLink: itm?.serviceNameLink,
                                receipt: itm?.receipt,
                                italian_state: itm?.italian_state
                              }
                            } key={uniqueIdBuilder()} />
                          )
                        }) :
                        <h4>Nessuna pratica presente</h4>
                    }
                  </div>
                </div>
              </section>
            </div>

          </>
        )
      case "Messaggi":
        return (
          <>
            <SideList
              intitle={"INDICE DELLA PAGINA"}
              infoList={[
                { element: "Messaggi", id: "#payments" },
              ]} />

            <div className="col-12 col-lg-8 offset-lg-1">
              <section className="it-page-section mb-40 mb-lg-60" id="messages">
                <div className="cmp-accordion">
                  <div className="accordion" id="">
                    {
                      messages.length > 0 ?
                        <ReminderTable
                          reminders={messages}
                          title={"Messaggi"}
                          isDoc={false}
                          opener=""
                          type={"messages"} /> :
                        <h4>Nessun messaggio presente</h4>
                    }
                  </div>
                </div>
              </section>
            </div>
          </>
        )
      case "Attività":
        return (
          <>
            <SideList
              intitle={"INDICE DELLA PAGINA"}
              infoList={[
                { element: "Pratiche", id: "#payments" },
                { element: "Pagamenti", id: "#payments" },
              ]} />

            <div className="col-12 col-lg-8 offset-lg-1">
              <section
                className="it-page-section mb-40 mb-lg-60"
                id="practices"
              >
                <div className="cmp-filter">
                  <div className="filter-section">
                    <h2 className="cmp-filter__title title-xxlarge">
                      Pratiche
                    </h2>
                  </div>
                  <br></br>

                </div>
                <div className="cmp-accordion" style={{ maxHeight: "350px", overflow: "auto", paddingRight: "10px" }}>
                  <div className="accordion" id="">
                    {
                      activities.length > 0 ?
                        activities.map((itm, idx) => {
                          return (
                            <CompletitionBox doc={
                              {
                                id: itm?.id,
                                name: itm?.name,
                                state: itm?.state,
                                date: itm?.date,
                                paymentCode: itm?.paymentCode,
                                paymentMethod: itm?.paymentMethod,
                                practiceNumber: itm?.practice_number,
                                amount: itm?.amount,
                                link: itm?.link,
                                nameLink: itm?.nameLink,
                                serviceLink: itm?.serviceLink,
                                serviceNameLink: itm?.serviceNameLink,
                                receipt: itm?.receipt,
                                italian_state: itm?.italian_state,
                                temp: itm?.temp,
                                protocolDate: itm?.protocol_date,
                                protocolId: itm?.protocol_id,
                                services
                              }
                            } key={uniqueIdBuilder()} />
                          )
                        }) :
                        <h4>Nessuna pratica presente</h4>
                    }
                  </div>
                </div>
              </section>

              <section
                className="it-page-section mb-50 mb-lg-90"
                id="payments">
                <div className="cmp-filter">
                  <div className="filter-section">
                    <h2
                      className="cmp-filter__title title-xxlarge"
                      id="payments">
                      Pagamenti
                    </h2>
                  </div>
                </div>
                <br></br>
                <div className="cmp-accordion" style={{ maxHeight: "350px", overflow: "auto", paddingRight: "10px" }}>
                  {
                    payments.length > 0 ?
                      payments.map((itm, idx) => {
                        // console.log("///////// payment", itm?.request?.data?.richiedente?.[0]?.anagrafica);
                        return (
                          <CompletitionBoxMsg
                            key={uniqueIdBuilder()}
                            title={itm?.title}
                            fileN={itm?.paymentCode}
                            serviceLink={itm?.serviceLink}
                            serviceNameLink={itm?.serviceNameLink}
                            serviceId={itm?.request?.service_id}
                            date={itm?.date}
                            paymentMethod={itm?.paymentMethod}
                            amount={itm?.amount}
                            pay={itm?.pay}
                            state={itm?.state}
                            link={itm?.link}
                            id={itm.request.id}
                            payID={itm?.id}
                            iuv={itm?.iuv}
                            type={itm?.type}
                            email={itm?.request?.data?.richiedente?.[0]?.contatti?.email}
                            padoPaUrl={itm?.urlPagoPA}
                          />
                        )
                      }) :
                      <>
                        <h4>Nessun pagamento presente</h4>
                        <div style={{ borderBottom: "solid 1px lightgrey" }}></div>
                      </>

                  }
                </div>
              </section>
            </div>
          </>
        )
      case "Servizi":
        return (
          <>
            <SideList
              intitle={"INDICE DELLA PAGINA"}
              infoList={[
                { element: "Servizi online", id: "#latest-posts" }
              ]} />

            <div className="col-12 col-lg-8 offset-lg-1">
              <div
                className="it-page-section mb-40 mb-lg-60"
                id="latest-posts"
              >
                <div className="cmp-card">
                  <div className="card">
                    <div className="card-header border-0 p-0 mb-lg-30 m-0">
                      <div className="d-flex">
                        <h2 className="title-xxlarge mb-3">
                          Servizi online
                        </h2>
                      </div>
                    </div>
                    <div className="card-body p-0">

                      <ReminderTable
                        reminders={header.services}
                        title={"Servizi"}
                        isDoc={false}
                        opener=""
                        type={"services"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      default:
        return <></>
    }
  }

  //setting up localStorage conf (once) when the component mounts?
  useEffect(() => {
    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }

    if (localStorage.getItem("user")) {
      const stored = JSON.parse(localStorage.getItem("user"));
      setUser(stored);
      setCurrent(JSON.parse(stored.data));
      setFiscalCcode(stored.fiscal_code);
    }
  }, []);

  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
      <div className="container" id="main-container">
        <div className="row justify-content-center">
          <WorkPath workPathInfo={{
            base: ["Home", header?.siteUrl],
            end: "Area Personale"
          }} />
          <div className="col-12 col-lg-10">
            <CompactInfo title={`${current?.name || ""} ${current?.familyName || ""}`} description={`CF: ${fiscal_code || ""}`} />
          </div>
          <TabHeader tabList={["Scrivania", "Messaggi", "Attività", "Servizi"]} tabSetter={tabSetter} />
        </div>

        <div className="it-page-sections-container">
          <div className="tab-content">
            <div className="row">
              {
                tabShower()
              }
            </div>
          </div>
        </div>
      </div>
      <HelpBox helpBoxInfo={{
        muniQuestionLink: header?.siteUrl + "domande-frequenti/",
        muniHelpLink: header?.siteUrl + "servizi/assistenza/",
        greenNumber: "",
        bookLink: header?.siteUrl + "servizi/prenotazioni/",
        rDisservice: ""
      }} />
    </AuthenticatedTenant>
  );
}
