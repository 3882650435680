import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import { getTenantCode } from "../../../util";

const DEFAULT_NOBONUS_VALUE = "Nessun bonus attivo al momento";

const VALUES_BY_TENANT = {

}

const getValues = () => {
    const tenantCode = getTenantCode();
    return VALUES_BY_TENANT[tenantCode] || VALUES_BY_TENANT["default"]
}

const getExtraFields = (context) => {
    const tenantCode = getTenantCode();
    const values = VALUES_BY_TENANT[tenantCode] || VALUES_BY_TENANT["default"];
    if(context && values?.extra_fields){
        let fieldsObj = {}
        values?.extra_fields?.map(field => {
            fieldsObj[field?.id] = context?.[field?.id] || field?.defaultValue || null;
        })
        // console.log("fieldsObj", fieldsObj)
        return fieldsObj;
    }

    return {}
}

export const useFee = (parentSaveFunc, config, parentSendFunc) => {
    /********************* hooks go down here *********************/
    const ctx = useSelector(state => state.stepSlice.data);
    // console.log("ctx", ctx)
    const ctxFee = useSelector(state => state.stepSlice.data.fee);
    const ctxApplicant = useSelector(state => state.stepSlice.data.fee.richiedente);
    const ctxPayment = useSelector(state => state.stepSlice.data.fee.pagamento);
    const ctxCode = useSelector(state => state.stepSlice.data.fee.code);
    const ctxInvoice = useSelector(state => state.stepSlice.data.fee.fatture);
    const ctxPrefs = useSelector(state => state.stepSlice.data.preferenze);
    const isStopped = useSelector(state => state.stepSlice.data.stopped);
    const dispatcher = useDispatch();

    // console.log("ctx bonus", ctxFee);
    /********************* state go down here *********************/
    const [data, setData] = useState({
        richiedente: ctxApplicant?.length > 0 ? [{ ...ctxApplicant[0], selected: true }] : [],
        pagamento: ctxPayment || {},
        code: ctxCode || "",
        fatture: ctxInvoice || {},
        ...getExtraFields(ctxFee)
    });
    const [values, setValues] = useState({
        ...getValues()
    })
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    /********************* methods go down here *********************/
    ////////////////////// get functions //////////////////////

    const getFields = () => {
        return ["richiedente", "code", "fatture", "pagamento", "preferenze"]
    }

    ////////////////////// validate functions //////////////////////
    const isValidField = (id, isSendRequest) => {
        switch(id){
            case "richiedente":
                return data[id]?.length > 0 ? true : false;
            case "preferenze":
                return true
            case "code":
                return data?.code?.length > 0
            case "pagamento":
            case "fatture":
                return data?.[id]?.iuv ? true : false
            default:
                return data[id] ? true : false
        }
    }

    const checkFields = (fields) => {
        // console.log("checkFields", fields)
        if(fields?.length > 0){
            let isValid = true;
            for(let key in fields){
                isValid = typeof isValidField(fields[key]) !== "object" && isValidField(fields[key]) === true;
                // console.log("field", data, fields[key], isValid)
                if(!isValid) break;
            }
            return isValid;
        }

        return false;
    }

    const isValidData = (isSendRequest) => {
        const tenantCode = getTenantCode();
        let requiredFields = [];
        // console.log("is valid data", isSendRequest)
        switch(tenantCode){
            default:
                if(isSendRequest) requiredFields = getFields()
                else requiredFields = []
        }

        if(requiredFields?.length > 0) return checkFields(requiredFields)        

        return true;
    }

    const checkErrors = () => {
        let newError = {...error}

        const fields = getFields() || []
        fields.map(field => {
            let isValid = isValidField(field, true)
            newError[field] = typeof isValid === "object" ? isValid : !isValid
        })
        
        setError(newError);
    }

    const isValidStep = (stepId) => {
        // console.log("data", data);
        const tenantCode = getTenantCode();
        switch(stepId){
            case "general":
                return (error?.preferenze) ? false : true
            case "specific":
            case "summary":
                return (error?.richiedente
                    || error?.pagamento
                    || error?.fatture
                    ) ? false : true
            default:
                return false;
        }
    }
    ////////////////////// UPDATE //////////////////////
    const parseField = (id, newData) => {
        switch(id){
            case "richiedente":
                return newData?.removing ? [] : [{ ...newData?.data?.[0], selected: true }]
            default:
                return newData?.data || null
        }
    }

    const update = (newData) => {
        // console.log("useFee update", newData, typeof newData === "object", Array.isArray(newData));
        if(typeof newData === "object" && !Array.isArray(newData)){
            const id = newData?.id?.toLowerCase();
            const internalId = newData?.internalId || null;
            if(id){
                if(internalId){
                    setData(oldData => ({
                        ...oldData,
                        [id]: {
                            ...(data?.[id] || {}),
                            [internalId]: newData?.data || null
                        }
                    }))
                }
                else{
                    setData(oldData => ({
                        ...oldData,
                        [id]: parseField(id, newData)
                    }))
                }
            }
        }
        else if (Array.isArray(newData)){
            let updatedData = {...data};
            newData.map(newDataItem => {
                const id = newDataItem?.id?.toLowerCase();
                const internalId = newDataItem?.internalId || null;
                if(id){
                    if(internalId){
                        updatedData = {
                            ...updatedData,
                            [id]: {
                                ...(updatedData?.[id] || {}),
                                [internalId]: newDataItem?.data || null
                            }
                        }
                    }
                    else{
                        updatedData = {
                            ...updatedData,
                            [id]: parseField(id, newDataItem)
                        }
                    }
                }
            })
            // console.log("updatedData", updatedData)
            setData(updatedData);
        }
    }

    const updateField = (e, fieldId, isInternal) => {
        // console.log("e", e?.target?.id, e?.target?.value);
        const id =(e?.id || e?.target?.id)?.toLowerCase();
        const value = e?.data || e?.target?.value;
        if((fieldId && id && isInternal) || (fieldId && !isInternal)){
            update({
                id: isInternal ? fieldId : id,
                internalId: isInternal ? id : null,
                data: value
            })
        }
    }

    ////////////////////// SAVE //////////////////////
    const savePrefs = () => {
        dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: "fee", data: {
            tipologia: data?.tipologia
        } }));
    }

    const save = async (isSendRequest) => {
        const fields = getFields() || []
        let saveData = {}
        // console.log("data", data);
        fields.map(field => {
            let isValid = typeof isValidField(field, isSendRequest) !== "object" && isValidField(field) === true;
            console.log("is valid data", field, isValid, data[field])
            if(data[field] && isValid) saveData[field] = data[field];
        })

        console.log("saveData", saveData);


        if(data?.tipologia){
            saveData.preferenze = {
                fee: {},
                // dichiaro: data?.dichiaro || [],
                // consapevole: data?.consapevole || [],
            }
        }

        // console.log("fields", saveData)
        // console.log("save data", saveData)
        let isSend = typeof isSendRequest === "boolean" ? isSendRequest : false;
        if(isValidData(isSend) && ((parentSaveFunc && !isSend) || (parentSendFunc && isSend))){
            dispatcher(stepSlice.actions.dynamicSave({ id: "Fee", data: {...saveData} }));
            savePrefs();
            setLoading(true);
            if(isSend){
                await parentSendFunc(saveData);
            }
            else{
                await parentSaveFunc(saveData);
            }
            setLoading(false);
        }
    }

    const clear = () => {
        setData({})
    }

    /********************* useEffects go down here *********************/
    useEffect(() => {
        // console.log("config", config);
        if((config?.data?.fee || config?.fee) && values?.fee?.length === 0){
            setValues({
                bonuses: (config?.data?.fee || config?.fee || [])?.map(bonus => ({id: bonus?.id || null, value: bonus.title || ""})) || []
            })
        }
    }, [config])

    useEffect(() => {
        // console.log("useFee", data);
        dispatcher(stepSlice.actions.dynamicSave({ id: "Fee", data: data || {} }));
        checkErrors();
    }, [data]);

    useEffect(() => {
        // console.log("useFee error", error, data);
    }, [error])

    useEffect(() => {
        checkErrors();
    }, [])

    return {
        data,        // current data of the service request
        values,      // values to populate fields such as dropdowns
        loading,     // true = is saving request 
        error,       // error flag by data[id] eg. data["richiedente"] is invalid -> error.richiedente = true
        save,        // function to save the current service request
        savePrefs,   // function to save prefs into the store
        update,      // function to update the data object
        updateField,
        isValidStep, // function to check <stepId> data -> useful for nextButton disable status
    }
}