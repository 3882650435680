import { getTenantCode } from "../util";

const getValues = (valuesByTenant) => {
    const tenantCode = getTenantCode();
    return valuesByTenant?.[tenantCode] || valuesByTenant?.["default"];
};

const getExtraFields = (valuesByTenant, context) => {
    const tenantCode = getTenantCode();
    const values = valuesByTenant?.[tenantCode] || valuesByTenant?.["default"];
    if (context && values?.extra_fields) {
        let fieldsObj = {};
        values?.extra_fields?.forEach(field => {
            fieldsObj[field?.id] = context?.[field?.id] || field?.defaultValue || null;
        });
        return fieldsObj;
    }
    return {};
};

////////// CONFIG //////
/**
 *  1. ADD SERVICE_ID case to dynamicSave setting SERVICE_INTERNAL_ID as state field
 *  2. ADD ServiceInternalId as state field to defaultPref and state.data
 */

export class Service {
    // static SERVICE_ID = "ServiceId" 
    // static SERVICE_INTERNAL_ID = "ServiceInternalId"
    //////////////////////////////////
    // Contructor
    //////////////////////////////////
    constructor(valuesByTenant, data, ctxService, serviceId, serviceInternalId) {
        this.data = {
            ...(data || {}),
            ...getExtraFields(valuesByTenant, ctxService)
        };
        this.values = getValues(valuesByTenant);
        this.error = {};
        this.SERVICE_ID = serviceId || "ServiceId"
        this.SERVICE_INTERNAL_ID = serviceInternalId || "ServiceInternalId"
    }

    //////////////////////////////////
    // Generic Methods
    //////////////////////////////////
    getFields(){
        const value = "";
        switch (value) {
            default:
                return ["richiedente", "preferenze"];
        }
    }

    getDefaultPrefs(){
        return {

        }
    }

    getPrefs(isSend){
        const tenantCode = getTenantCode();
        switch(tenantCode){
            default:
                return {
                    ...this.getDefaultPrefs(),
                }
        }
    }

    getRequestData(isSendRequest){
        let saveData = {}
        const fields = this.getFields();
        const prefs = this.getPrefs()
        fields.map(field => {
            let isValid = typeof this.isValidField(field, isSendRequest) !== "object" && this.isValidField(field) === true;
            // console.log("is valid data", field, isValid)
            if(this.data[field] && isValid) saveData[field] = this.data[field];
        })
        return {
            ...saveData,
            preferenze: {
                [this.SERVICE_INTERNAL_ID]: prefs
            }
        };
    }

    getData(){
        return this.data;
    }

    getValues(){
        return this.values;
    }

    clear(){
        this.data = {}
        return this.data;
    }
    //////////////////////////////////
    // Field validation
    //////////////////////////////////
    isValidField(id) {
        // console.log("is valid field", id, this.data[id])
        switch (id) {
            case "richiedente":
                return this.data[id]?.length > 0;
            case "nucleo_familiare":
                return this.data[id]?.length >= 0;
            case "marca_bollo":
                return this.data?.marca_bollo?.numero?.length === 14 && this.data?.marca_bollo?.data;
            default:
                return this.data[id] ? true : false;
        }

    }

    isValidData(isSendRequest, fields){
        let requiredFields = [];
        // console.log("is valid data", isSendRequest)
        if(isSendRequest) requiredFields = fields || this.getFields()
        else requiredFields = []

        if(requiredFields?.length > 0) return this.checkFields(requiredFields)        

        return true;
    }

    checkFields(){
        const fields = this.getFields();
        if(fields?.length > 0){
            let isValid = true;
            for(let key in fields){
                isValid = typeof this.isValidField(fields[key]) !== "object" && this.isValidField(fields[key]) === true;
                if(!isValid) {
                    console.log("check fields", key, fields[key])
                    break;
                }
            }
            return isValid;
        }

        return false;
    }

    checkErrors() {
        let newError = {};
        this.getFields().forEach(field => {
            let isValid = this.isValidField(field, true)
            // console.log("field", this.data, field, isValid)
            newError[field] = typeof isValid === "object" ? isValid : !isValid
        });
        this.error = newError;
        // console.log("check errors", newError, this.data)
        return this.error;
    }
    //////////////////////////////////
    // Field parsing
    //////////////////////////////////
    parseField(id, newData){
        switch(id){
            case "richiedente":
                return newData?.removing ? [] : [{ ...(newData?.data?.[0] || newData?.data), selected: true }]
            case "immobili":
                return newData?.removing ? {} : {...newData?.data}
            case "nucleo_familiare":
                // console.log("nucleo_familiare", data, newData)
                if(!newData?.removing && !newData?.isEdit){
                    return [
                        ...(this.data[id] || []),
                        {...newData?.data?.[0]}
                    ]
                }
                else if(newData?.removing && newData?.index !== undefined){
                    let newArrayData = []
                    this.data[id]?.map((member, idx) => {
                        if(idx !== newData?.index)
                            newArrayData.push(member)
                    })
                    // console.log("newArrayData", newArrayData)
                    return newArrayData
                }
                else if(newData?.isEdit){
                    let newArrayData = [...(this.data[id] || [])]
                    if(newArrayData[newData?.index])
                        newArrayData[newData?.index] = newData?.data?.[0]

                    return newArrayData
                }
                // return newData?.removing ? [] : [{ ...newData?.data?.[0], selected: true }]
                break;  
            default:
                return newData?.data || null
        }
    }
    //////////////////////////////////
    // Steps validation
    //////////////////////////////////
    isValidStep(stepId){
        this.checkErrors();
        switch(stepId){
            case "general":
                return (this.error?.richiedente) ? false : true
            case "specific":
            case "summary":
                return (
                    this.error?.richiedente 
                ) ? false : true
            default:
                return false;
        }
    }
    //////////////////////////////////
    // Update methods
    //////////////////////////////////
    update(newData){
        // let currentData = {...this.data};
        // console.log("service update", newData, typeof newData === "object", Array.isArray(newData));
        if(typeof newData === "object" && !Array.isArray(newData)){
            const id = newData?.id?.toLowerCase()?.replace(" ", "_");
            const internalId = newData?.internalId || null;
            if(id){
                if(internalId){
                    this.data =  {
                        ...this.data,
                        [id]: {
                            ...(this.data?.[id] || {}),
                            [internalId]: newData?.data || null
                        }
                    }
                }
                else{
                    this.data =  {
                        ...this.data,
                        [id]: this.parseField(id, newData)
                    }
                }
            }
        }
        else if (Array.isArray(newData)){
            let updatedData = {...this.data};
            newData.map(newDataItem => {
                const id = newDataItem?.id?.toLowerCase()?.replace(" ", "_");
                const internalId = newDataItem?.internalId || null;
                if(id){
                    if(internalId){
                        updatedData = {
                            ...updatedData,
                            [id]: {
                                ...(updatedData?.[id] || {}),
                                [internalId]: newDataItem?.data || null
                            }
                        }
                    }
                    else{
                        updatedData = {
                            ...updatedData,
                            [id]: this.parseField(id, newDataItem)
                        }
                    }
                }
            })
            // console.log("updatedData", updatedData)
            this.data =  {
                ...updatedData
            }
        }

        // this.checkErrors();
        return this.data
    }

    updateField(e, fieldId, isInternal, customId) {
        const id =(customId || e?.id || e?.target?.id)?.toLowerCase();
        const value = e?.data || e?.target?.value;
        // console.log("updateField", id, value, (fieldId && id && isInternal) || (fieldId && !isInternal));
        if((fieldId && id && isInternal) || (fieldId && !isInternal) || (id && value)){
            return this.update({
                id: isInternal ? fieldId : id,
                internalId: isInternal ? id : null,
                data: value
            })
        }

        // this.checkErrors();
        return this.data;
    }

    updatePrefs(newPreferences){
        const prefsFields = Object.keys(this.getPrefs());
        if(prefsFields?.length > 0 && newPreferences?.data?.matrimonio){
            let updatedPrefs = {};
            Object.keys(newPreferences?.data?.matrimonio)?.map(key => {
                if(prefsFields?.indexOf(key) !== -1)
                    updatedPrefs[key] = newPreferences?.data?.matrimonio[key];
            })
            // console.log("new preferences", newPreferences)
            this.data = {
                ...this.data,
                ...updatedPrefs
            }

        }

        return this.data;
    }
}