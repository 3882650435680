import React, { useEffect, useState } from "react";
import CustomerFormBox from "../../UI-component/registryForms/CustomerFormBox";
import IseeFormBox from "../../UI-component/registryForms/IseeFormBox";
import SideList from "../../UI-component/SideList";
import ErrorAlert from "../../UI-component/ErrorAlert";
import { useSelector, useDispatch } from "react-redux";
import { titleNormalizer, uniqueIdBuilder } from "../../util/Dynamics";
import stepSlice from "../../context/StepsContext";
import NextBtnForm from "../../UI-component/NexBtnForm";
import SuccessfullPopup from "../../UI-component/SuccessfullPopup";

const formatSavedBen = (data) => {
  if (data) {
    let newData = [...data]
    for (let key in data) {
      newData[key] = { ...data[key], id: `ben-${key}` }
    }

    return newData;
  }
  return []
}

export default function ServiziPagamentoDatiPersonali(props) {
  const dispatcher = useDispatch();
  const dataCtx = useSelector(state => state.stepSlice.data);
  const isStopped = useSelector(state => state.stepSlice.data.stopped);

  // console.log("slice data", dataCtx)
  const [error, setError] = useState({
    richiedente: false,
    beneficiario: false,
  });
  const [lilErr, setLilErr] = useState(false);

  const chk = () => {
    let fullObj = structuredClone(dataCtx);
    // if (dataCtx.isee.length === 0)
    //   fullObj.isee = props.info.testData.isee;

    return fullObj;
  }

  const [typeData, setTypeData] = useState(chk());
  const [savedBen, setSavedBen] = useState(formatSavedBen(dataCtx?.savedBen?.length > 0 ? dataCtx?.savedBen : props.info?.testData?.savedBen || []));

  // console.log("SAVED BEN", savedBen);

  const update = (data) => {
    if (typeof data === "object") {
      setTypeData(prev => {
        const obj = structuredClone(prev);

        const id = titleNormalizer(data.id);

        if (data?.change) {
          const temp = data.data;
          temp[0].selected = true;
          obj[titleNormalizer(data.id)] = temp;
          prev = obj;

          return { ...prev }
        }

        if (data?.removing) {
          obj[id] = undefined;
          prev = obj;
          return { ...prev };
        }

        const temp = data.data;
        // console.log("temp", temp);
        temp[0].selected = true;
        temp[0].id = data?.data[0]?.id || `ben-${savedBen?.length}`;
        // console.log("temp", temp);
        obj[titleNormalizer(data.id)] = temp;
        prev = obj;
        // console.log("update data", "enter here", temp);


        if (data?.id.toLowerCase() === "beneficiario") {
          setSavedBen(prev => {
            let obj = structuredClone(prev);
            let alreadyIn = false;

            for (let itm of obj) {
              if (("" + itm.anagrafica.codice_fiscale)?.toLowerCase() ===
                ("" + data.data[0].anagrafica.codice_fiscale)?.toLowerCase() && !data.isEdit) {
                alreadyIn = true;
                break;
              }
              else
                alreadyIn = false;
            }

            if (!alreadyIn) {
              let benIndex = prev.findIndex(el => el?.id === data?.data[0]?.id);
              // console.log("searching ben", data, benIndex, data?.data[0]?.id)
              if (benIndex === -1) {
                obj.push({ ...data.data[0], id: `ben-${obj?.length}` });
              }
              else {
                obj[benIndex] = { ...obj[benIndex], ...data?.data[0] };
              }
              setLilErr(false);
            }
            else
              setLilErr(true)

            return [...obj];
          })
        }

        return { ...prev };
      });
    }
  }

  // Dynamically renders the box, checking if 
  // any data resides in the context. If data 
  // is present in the context it is gonna retrieve that.
  // "It is gonna be swapping between api data and context data"
  const dyanmicRenderer = (itm, idx) => {
    let cards = [];

    switch (itm.type) {
      case "Person":
        for (let i of dynamicData(itm?.title)) {
          if (i !== undefined)
            cards.push(i)
        }

        if (itm?.title === "Beneficiario") {
          const tmp = cards?.[cards.length - 1] || null;
          const selectedTmp = cards?.filter(el => el?.selected === "1")?.[0]?.[0];
          cards = [];
          if(selectedTmp) cards.push([selectedTmp])
          else if(tmp) cards.push(tmp);
          // console.log("cards", cards);
          // console.log("beneficiario", tmp, cards?.[0] ? (Array.isArray(cards[0][0]) ? [cards[0][0]] : (cards[0][0] ? [cards[0]] : [])) : [tmp]);

          return (
            <>
              <CustomerFormBox
                id={itm?.title}
                cards={cards?.[0] ? (Array.isArray(cards[0][0]) ? [cards[0][0]] : (cards[0][0] ? [cards[0]] : [])) : [cards]}
                description={itm?.title === "Beneficiario" ? "Informazioni sulla persona che utilizzerà il servizio " : "Informazioni su di te"}
                type={false}
                title={itm?.title}
                service={props.info.code}
                optionalTitle={itm?.optional_title}
                update={update}
                key={uniqueIdBuilder()}
                prefData={props.info?.testData[titleNormalizer(itm?.title)]}
                savedBen={savedBen}
                lilErr={lilErr}
              />
            </>
          )
        } else {
          return (
            <CustomerFormBox
              id={itm?.title}
              cards={cards[0] ? Array.isArray(cards[0][0]) ? [cards[0][0]] : cards[0][0] ? [cards[0]] : [] : cards}
              description={itm?.title === "Beneficiario" ? "Informazioni sulla persona che utilizzerà il servizio" : "Informazioni su di te"}
              type={false}
              title={itm?.title}
              service={props.info.code}
              optionalTitle={itm?.optional_title}
              update={update}
              key={uniqueIdBuilder()}
              prefData={props.info?.testData[titleNormalizer(itm?.title)]}
              savedBen={savedBen}
              lilErr={lilErr}
            />
          )
        }


      case "Isee":
        let trest = null;

        for (let i of dynamicData(itm?.title)) {
          if (i !== undefined)
            trest = i;
        }

        //older directly creates the card
        // return (
        //   <IseeFormBox
        //     id={itm?.title}
        //     cards={trest ?? []}
        //     optionalTitle={itm?.optional_title}
        //     update={update}
        //     key={uniqueIdBuilder()} />
        // )


        return (
          <IseeFormBox
            id={itm?.title}
            cards={trest ?? []}
            optionalTitle={itm?.optional_title}
            update={update}
            key={uniqueIdBuilder()}
            placeholderData={props.info?.testData?.isee || []} />
        )
      default:
        return <></>;
    }
  }
  // Dynamically checks the key of the given object
  // and return the context object filtered by the title
  const dynamicData = (title) => {
    return Object.keys(typeData).map(key => {
      if (typeData[key] !== undefined /*&& !Array.isArray(typeData[key])*/) {
        if (title.toLowerCase() === key) {
          if (Array.isArray(typeData[key]))
            return typeData[key].map(itm => itm)
          else {
            return typeData[key];
          }
        }
      }
    })
  }

  const onNext = () => {
    for (let i of props.info.data.components) {
      if (typeData[titleNormalizer(i.title)] !== null) {
        if (Array.isArray(typeData[titleNormalizer(i.title)]))
          dispatcher(stepSlice.actions.dynamicSave({ id: i.title, data: typeData[titleNormalizer(i.title)][0] }));
        else
          dispatcher(stepSlice.actions.dynamicSave({ id: i.title, data: typeData[titleNormalizer(i.title)] }));
      }
    }

    dispatcher(stepSlice.actions.dynamicSave({ id: "SavedBen", data: savedBen }));

    props.next();
  }

  const errorChecker = () => {
    const err = {
      richiedente: true,
      beneficiario: true,
    };

    if (typeData?.richiedente?.length > 0)
      err.richiedente = false;
    if (typeData?.beneficiario?.length > 0)
      err.beneficiario = false;

    setError(err);
  }

  const save = async () => {
    // console.log(typeData);

    if (typeData?.length === 0) return;

    for (let i of props.info.data.components) {
      if (typeData[titleNormalizer(i.title)] !== null) {
        if (Array.isArray(typeData[titleNormalizer(i.title)]))
          dispatcher(stepSlice.actions.dynamicSave({ id: i.title, data: typeData[titleNormalizer(i.title)][0] }));
        else
          dispatcher(stepSlice.actions.dynamicSave({ id: i.title, data: typeData[titleNormalizer(i.title)] }));
      }
    }

    dispatcher(stepSlice.actions.dynamicSave({ id: "SavedBen", data: savedBen }));

    let data = structuredClone(typeData);
    data.savedBen = savedBen;

    if (Array.isArray(typeData.beneficiario))
      data.beneficiario = { 0: Array.isArray(typeData.beneficiario[0]) ? typeData.beneficiario[0][0] : typeData.beneficiario[0] };

    props.save(data);
  }

  useEffect(() => {
    errorChecker();
  }, [typeData])

  useEffect(() => {
    dispatcher(stepSlice.actions.dynamicSave({ id: "SavedBen", data: [] }));
  }, [])

  return (
    <div className="row">
      <SideList
        infoList={props.info.data?.general_side_list || []}
      />
      <div className="col-12 col-lg-8 offset-lg-1">
        {
          <ErrorAlert errors={[{ name: "Richiedente", id: "#isee-info" }]} err={error.richiedente || isStopped} />
        }

        {
          <ErrorAlert errors={[{ name: "Beneficiario", id: "#isee-info" }]} err={error.beneficiario} />
        }

        <div className="it-page-sections-container">
          {
            props.info.data.components.map((itm, idx) => dyanmicRenderer(itm, idx))
          }
        </div>

        <NextBtnForm
          saveRequest={save}
          next={onNext}
          back={props.back}
          disabled={error.richiedente || error.beneficiario || isStopped} />

        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }

      </div>
    </div>
  );
}
