import React, { useEffect, useState } from "react";
import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import WorkPath from "../../UI-component/WorkPath";
import DynamicStepContent from "../../UI-component/DynamicStepContent";
import Privacy from "../_Partials/Privacy";
import { ServiceDetailsAPI } from "../../Apis/ServiceDetailsAPI";
import General from "../_Partials/Fines/General";
import Specific from "../_Partials/Fines/Specific";
import Summary from "../_Partials/Fines/Summary";
import PaymentPage from "../_Partials/PaymentPage";
import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";
import { useLocation } from "react-router-dom";

function Fines(props) {
  const dispatcher = useDispatch();

  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const [testData, setTestData] = useState({});
  const [bread, setBread] = useState({});
  const [data, setData] = useState({});
  const location = useLocation();

  const getData = async () => {
    try {
      const res = await ServiceDetailsAPI.getServiceDetails(props.code);

      if (!res.success) return;

      const data = res.data?.data;
      setData(data);
    } catch (err) {
      console.log(err);
    }
  }

  const clearStore = () => {
    dispatcher(stepSlice.actions.clear())
  }

  //initialize localStorage conf once when component mounts ?
  useEffect(() => {
    //inizioalize first view first step
    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
      clearStore();
    }
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
      clearStore();
    }

    (async () => {
      try {
        const bread = await ServiceDetailsAPI.getBreadcrumbs("PCO");
        setBread(bread);
        await getData();
      } catch (err) {
        console.log(err);
      }
    })()

    if (location.state) {
      // console.log("saved data", location.state);
      if(location?.state?.data?.richiedente?.[0]?.anagrafica?.codice_fiscale)
        dispatcher(stepSlice.actions.onDraftData({ data: location.state.data, saved: false }));
      else if(location?.state?.data?.multa){
        dispatcher(stepSlice.actions.dynamicSave({ id: "multa", data: location?.state?.data?.multa }));
      }

      dispatcher(stepSlice.actions.dynamicSave({ id: "practiceNumber", data: location?.state?.practice_number }));
    }

    if (!location.state) {
      (async () => {
        try {
          const saved = await ServiceDetailsAPI.getSaved("pco");

          if (!saved.success) {
            return;
          }

          // console.log("saved data", saved)
          // setTestData(saved?.data);
          dispatcher(stepSlice.actions.onDraftData({ data: saved?.data, saved: false }));

        } catch (err) {
          console.log(err);
        }
      })()
    }
  }, []);

  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
      <WorkPath workPathInfo={{
        base: ["Home", header?.siteUrl],
        medium: ["Servizi", header?.siteUrl + "servizi/"],
        category: [bread.category_name, bread.category_url],
        serviceName: [bread.service_name, bread.service_url],
        end: "Servizio digitale",
      }} />

      <div className="container" id="main-container">
        <div className="row justify-content-center" >
          <DynamicStepContent service={props.code}
            successTitle={"Pagamento contravvenzioni"}
            successDesc={`
            Inserisci le informazioni necessarie per pagare le contravvenzioni.<br>
            Potrai ricontrollare tutti i dati nel riepilogo, prima di inviare la richiesta.
              `}
            stepList={["INFORMATIVA SULLA PRIVACY", "DATI GENERALI", "DATI SPECIFICI", "RIEPILOGO", "PAGAMENTO"]} steps={[
              {
                component: Privacy, info: {
                  description: `Il ${JSON.parse(localStorage.getItem("configuration")).data.current.description}
                  esercita compiti e funzioni e gestisce servizi come indicato negli artt. 13/14 del Testo Unico Enti Locali (D.lgs. 267/2000 e ss integrazioni).
                  In questo contesto procede al trattamento dei dati personali dell’interessato necessari per fornire ogni tipo 
                  di servizio nel rispetto della normativa vigente (codice protezione dati personali e Regolamento europeo 16/679).
                  Per una completa informazione si invita a prendere conoscenza del contenuto della informativa resa ex art. 13
                  Regolamento europeo.`,
                  linkPrivacy: "",
                }
              },
              { component: General, info: { data: testData, code: props.code } },
              { component: Specific, info: { data: testData, code: props.code } },
              {
                component: Summary, info: {
                  data: testData, code: props.code,
                  headData: {
                    title: "Pagamento contravvenzioni",
                    description: `Inserisci le informazioni necessarie per pagare le contravvenzioni.<br>
                                  Potrai ricontrollare tutti i dati nel riepilogo, prima di inviare la richiesta.`
                  }
                }
              },
            ]} />

        </div>
      </div>

    </AuthenticatedTenant>
  );
}

export default Fines;