import React, { useEffect, useState } from "react";

import { Alert } from "react-bootstrap";
import { getStoredConfiguration } from ".";

const ProtectedRoute = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [config, setConfig] = useState(false);

  const checkUserToken = () => {
    const userToken = localStorage.getItem("access_token");

    if (!userToken) {
      setIsLoggedIn(false);
      // if (config.current === null)
      //   return <Alert> Dominio non gestito</Alert>;
      // else {
      localStorage.setItem("destination", window.location.pathname);
      window.location.href = "/login-spid";
      // }
    } else
      setIsLoggedIn(true);
  };

  useEffect(() => {
    checkUserToken();
  }, []);

  return (
    <React.Fragment>
      {
        isLoggedIn &&
        props.children
      }
    </React.Fragment>
  );
};
export default ProtectedRoute;
