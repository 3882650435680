import React, { useEffect, useState } from "react";
import PopupTextField from "../ui-el/PopupTextField";
import { checkValidity, onDataChange2, onDirectDataChange, onFileSet2, titleNormalizer, uniqueIdBuilder } from "../../util/Dynamics";
import PopupBox from "../PopupBox";
import SelectionFormBox from "../SelectionFormBox";
import PopupSelectField from "../ui-el/PopupSelectField";

const REASONS = [
  "dimora abituale in altro Comune per gravi motivi di salute debitamente certificati",
  "periodo di studio, lavoro o volontariato fuori dalla Regione di almeno un anno debitamente certificati, gli studenti non devono aver compiuto il 26° anno di età",
  "degenti presso case di cura o di riposo, comunità di recupero, centri socio-educativi, istituti penitenziari e simili per un periodo non inferiore all'anno debitamente certificati"
]
function DATFamilyMemberForm(props) {
  const [data, setData] = useState(props.defaultValue || {})

  const onChange = (e) => {
    if(props?.setCustomerData) onDataChange2(e, props?.setCustomerData )
    else {
      setData(prev => {
        const id = titleNormalizer(e.target.id)
        return {
          ...prev,
          anagrafica: {
            ...(data?.anagrafica || {}),
            [id]: e?.target?.value
          }
        }
      })
    }
  }

  const onChoice = (e) => {
    // console.log("e", e);
    const id = titleNormalizer(e.target.id)
    const value = e?.target?.options?.[e.target.selectedIndex].text
    if(!id) return;
    setData(prev => {
      return {
        ...prev,
        anagrafica: {
          ...(data?.anagrafica || {}),
              [id]: value
        }
      }
    })
  }

  const save = () => {
    if (checkValidity(data)) {
      // console.log("data", data);
      // dispatcher(stepSlice.actions.dynamicEdit({id: "Coobbligato",data: cobData}));
      props.update({
        id: "nucleo_familiare",
        isEdit: true,
        data: [data]
      });
      props.close();
    }
  }

  // useEffect(() => {
  //   console.log("data changed", data)
  // }, [data] )

  return (
    <PopupBox close={props.close} save={props.save || save} title={props?.title || props?.id}>
      {
        props.error &&
        <p style={{ color: "red", fontWeight: "bolder" }}>Completa tutti i campi</p>
      }

      <PopupTextField
        defaultValue={props?.defaultValue?.anagrafica?.nome}
        onChange={onChange}
        fieldType="text"
        id="nome"
        fieldTitle="Nome*"
        description={" "}
        error={props.error}
      />
      <PopupTextField
        defaultValue={props?.defaultValue?.anagrafica?.cognome}
        onChange={onChange}
        fieldType="text"
        id="cognome"
        fieldTitle="Cognome*"
        description={" "}
        error={props.error}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="date"
        id="data_di_nascita"
        fieldTitle="Data di nascita*"
        defaultValue={props?.defaultValue?.anagrafica?.data_di_nascita}
        error={props.error}
      />
      <PopupTextField
        description={" "}
        defaultValue={props?.defaultValue?.anagrafica?.anni}
        onChange={onChange}
        fieldType="number"
        min={120}
        id="anni"
        fieldTitle="Anni compiuti alla data di presentazione della domanda*"
        error={props.error}
      />

      <PopupTextField
        description={" "}
        defaultValue={props?.defaultValue?.anagrafica?.grado_parentela}
        onChange={onChange}
        fieldType="text"
        id="grado_parentela"
        fieldTitle="Grado di parentela*"
        error={props.error}
      />

      {/* <PopupSelectField
        defaultValue={props?.defaultValue?.anagrafica?.motivo}
        onChange={onChange}
        id={"Motivo"}
        fieldTitle={"Motivo"}
        options={REASONS}
        description="Seleziona il motivo"
        key={uniqueIdBuilder()} /> */}

      <PopupSelectField
        defaultValue={props?.defaultValue?.anagrafica?.motivo}
        onChange={onChoice}
        id="motivo"
        fieldTitle={"Motivo"}
        options={REASONS}
        description="Seleziona il tipo di tichiedente" />
        
      {/* <SelectionFormBox
        showTitle={`Motivo`}
        title={"motivo"}
        description={" "}
        choices={REASONS}
        defaultValue={props?.defaultValue?.anagrafica?.motivo}
        classDefaultValue={""}
        oKey={""}
        update={updateField}
        disabled={"  " === "" ? "disabled" : ""} /> */}

      {/* <PopupTextField
        description={" "}
        defaultValue={props?.defaultValue?.anagrafica?.grado_parentela}
        onChange={onChange}
        fieldType="text"
        id="grado_parentela"
        fieldTitle="Grado di parentela*"
        error={props.error}
      /> */}
    </PopupBox>
  )
}

export default DATFamilyMemberForm;
