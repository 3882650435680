import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import CustomerFormBox from "../../../UI-component/registryForms/CustomerFormBox";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import stepSlice from "../../../context/StepsContext";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";
import { useMarriage } from "./marriage";


function General(props) {
  const {data, values, error, loading, update, save, savePrefs, isValidStep} = useMarriage(props.save, props?.info?.data || {});

  const onNext = () => {
    savePrefs();
    props.next();
  }

  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Richiedente 1", id: "#report-author" },
          { element: "Richiedente 2", id: "#report-author" },

        ]} />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">

            {
              <ErrorAlert errors={[{ name: "Richiedente 1", id: "#isee-info" }]} err={error.richiedente} />
            }
            {
              <ErrorAlert errors={[{ name: "Richiedente 2", id: "#isee-info" }]} err={error.richiedente_2} />
            }

            <CustomerFormBox id="richiedente"
              customId="richiedente"
              cards={data?.richiedente?.length > 0 ? [data?.richiedente] : []}
              type={false}
              description="Informazione su di te"
              title="Richiedente 1"
              service={props.info.code}
              update={update}
              prefData={props.info.data?.richiedente}
            />


            <CustomerFormBox id="richiedente 2"
              cards={data?.richiedente_2?.length > 0 ? [data?.richiedente_2] : []}
              type={false}
              description="Informazione sulla persona con cui ti sposerai"
              title="Richiedente 2"
              service={props.info.code}
              customBtnTitle={"richiedente"}
              update={update}
              prefData={props.info.data?.richiedente_2}
            />
          </div>
        </div>

        <Loader loading={loading} fixedPosition/>
        <NextBtnForm
          next={onNext}
          back={props.back}
          noSave={false}
          saveRequest={save}
          disabled={!isValidStep("general")} />
        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  )
}

export default General;