import React, { useEffect, useState } from "react";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import PopupTextField from "../../../UI-component/ui-el/PopupTextField";
import Loader from "../../../UI-component/Loader";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import { uniqueIdBuilder } from "../../../util/Dynamics";
import PopupSelectField from "../../../UI-component/ui-el/PopupSelectField";
import { useMarriage } from "./marriage";

function Specific(props) {
    const {data, values, error, loading, update, updateField, save, savePrefs, isValidStep} = useMarriage(props.save, props?.info?.data || {});

    const onNext = () => {
        savePrefs();
        props.next();
    }

    return (
        <div className="row">
            <SideList
                infoList={[
                    { element: "Tipo di Matrimonio", id: "#report-author" },
                    { element: "Data del matrimonio (presumibile)", id: "#report-author" },
                    { element: "Marca da bollo 1", id: "#report-author" },
                    { element: "Marca da bollo 2", id: "#report-author" },
                ]} />

            <div className="col-12 col-lg-8 offset-lg-1">
                <div className="steppers-content" aria-live="polite">
                    <div className="it-page-sections-container">

                        <ErrorAlert errors={[{ name: "Tipo di Matrimonio", id: "#isee-info" }]} err={error.tipo_matrimonio} />
                        <ErrorAlert errors={[{ name: "Marca da bollo 1", id: "#isee-info" }]} err={error.marca_bollo_1} />
                        <ErrorAlert errors={[{ name: "Marca da bollo 2", id: "#isee-info" }]} err={error.marca_bollo_2} />
                        <ErrorAlert errors={[{ name: "Data del matrimonio (presumibile)", id: "#isee-info" }]} err={error.date} />

                        <br></br>

                        <SummaryCard
                            type="Tipo di matrimonio*"
                            description={""}>
                            {

                                <PopupSelectField
                                    defaultValue={data?.tipo_matrimonio}
                                    onChange={updateField}
                                    id={"tipo_matrimonio"}
                                    fieldTitle={"Tipo di Matrimonio"}
                                    options={[
                                        "Civile",
                                        "Concordatario",
                                        "Acattolico",
                                        "Altro",
                                    ]}
                                    description=""
                                    key={uniqueIdBuilder()} />
                            }
                        </SummaryCard>

                        <SummaryCard type="Data del matrimonio (presumibile)*">
                            <PopupTextField
                                defaultValue={data?.data_matrimonio}
                                onChange={updateField}
                                id="data_matrimonio" fieldType="date"
                                fieldTitle="Data"
                                description=""
                            />

                        </SummaryCard>

                        <SummaryCard type="Marca da bollo 1*">
                            <PopupTextField
                                defaultValue={data?.marca_bollo_1?.numero}
                                onChange={(e) => { updateField(e, "marca_bollo_1", true, "numero")}}
                                id="numero_1"
                                fieldType="text"
                                fieldTitle="Numero"
                                description=""
                                regex="^[0-9]{14}$"
                                max="14"
                                min="14" />
                            <PopupTextField
                                defaultValue={data?.marca_bollo_1?.data}
                                onChange={(e) => { updateField(e, "marca_bollo_1", true, "data")}}
                                id="data_1"
                                fieldType="date"
                                fieldTitle="Data"
                                description=""
                                min={new Date().toISOString().split('T')[0]}
                            />
                        </SummaryCard>

                        <SummaryCard type="Marca da bollo 2*">
                            <PopupTextField
                                defaultValue={data?.marca_bollo_2?.numero}
                                onChange={(e) => { updateField(e, "marca_bollo_2", true)}}
                                id="numero"
                                fieldType="text"
                                fieldTitle="Numero"
                                description=""
                                regex="^[0-9]{14}$"
                                max="14"
                                min="14" />
                            <PopupTextField
                                defaultValue={data?.marca_bollo_2?.data}
                                onChange={(e) => { updateField(e, "marca_bollo_2", true)}}
                                id="data"
                                fieldType="date"
                                fieldTitle="Data"
                                description=""
                                min={new Date().toISOString().split('T')[0]}
                            />
                        </SummaryCard>
                    </div>
                </div>
                <Loader loading={loading} fixedPosition/>

                <NextBtnForm
                    next={onNext}
                    back={props.back}
                    noSave={false}
                    saveRequest={save}
                    disabled={!isValidStep("specific")}
                />
                {
                    props.saved &&
                    <SuccessfullPopup />
                }

                {
                    props.fail &&
                    <SuccessfullPopup isError={true} />
                }
            </div>
        </div>
    )
}

export default Specific;