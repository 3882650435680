import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import CustomerFormBox from "../../../UI-component/registryForms/CustomerFormBox";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import stepSlice from "../../../context/StepsContext";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import PopupTextField from "../../../UI-component/ui-el/PopupTextField";
import { onFileSet2 } from "../../../util/Dynamics";
import IseeFormBox from "../../../UI-component/registryForms/IseeFormBox";
import FamilyFormBox from "../../../UI-component/registryForms/FamilyFormBox";
import { getTenantCode } from "../../../util";
import CheckedFormBox from "../../../UI-component/CheckedFormBox";
import SelectionFormBox from "../../../UI-component/SelectionFormBox";
import { useService } from "../../../classes/useService";
import { TaxBreaksService } from "../../../classes/taxBreaks/TaxBreakService";
import CustomerPropertyFormBox from "../../../UI-component/registryForms/CustomerPropertyFormBox";
import PopupSelectField from "../../../UI-component/ui-el/PopupSelectField";

function Specific(props) {
  const iseeRequired = useSelector(() => true);
  const isStopped = useSelector(state => state.stepSlice.data.stopped);

  // console.log("bonus", ctx);
  const {data, loading, error, values, save, savePrefs, update, updateField, isValidStep} = useService(TaxBreaksService, props.save, props?.info?.data || {});

  const [files, setFiles] = useState({});

  const [familyMembers, setFamilyMembers] = useState([]);

  const onNext = () => {
    savePrefs()
    props.next();
  }

  const getInfoList = () => {
    const value = "";
    switch(value){
      // case "BA3":
      //   return [
      //     { element: "Richiedente", id: "#report-author" },
      //     { element: "ISEE", id: "#report-author" },
      //     { element: "Nucleo familiare", id: "#report-author" },
      //   ]
      // case "CAP":
      //   return [
      //     { element: "Richiedente", id: "#report-author" },
      //     { element: "ISEE", id: "#report-author" },
      //     { element: "Dati per l'accredito", id: "#report-author" },
      //     { element: "Costi", id: "#report-author" },
      //     { element: "Requisiti", id: "#report-author" },
      //     { element: "Marca da bollo", id: "#report-author" },
      //     { element: "Dichiaro", id: "#report-author" },
      //   ]
      default:
        return [
          { element: "Richiedente", id: "#report-author" },
          { element: "ISEE", id: "#report-author" },
          { element: "Dati per l'accredito", id: "#report-author" },
        ]
    }
  }

  // const renderCreditInfo = () => {
  //   return(
  //     <SummaryCard
  //       type="Dati per l'accredito*"
  //       description={"Inserisci i dati per l'accredito"}>
  //       <PopupTextField
  //         defaultValue={data?.credito?.intestatario}
  //         fieldType="text"
  //         id="intestatario"
  //         fieldTitle="Intestatario*"
  //         description="Inserisci l'intestatario"
  //         max="18"
  //         onChange={onChange}
  //       />
  //       <PopupTextField
  //         defaultValue={data?.credito?.banca}
  //         fieldType="text"
  //         id="banca"
  //         fieldTitle="Banca*"
  //         description="Inserisci il nome della banca"
  //         max="18"
  //         onChange={onChange}
  //       />
  //       <PopupTextField
  //         defaultValue={data?.credito?.filiale}
  //         fieldType="text"
  //         id="filiale"
  //         fieldTitle="Filiale*"
  //         description="Inserisci la filiale"
  //         max="18"
  //         onChange={onChange}
  //       />
  //       <PopupTextField
  //         defaultValue={data?.credito?.indirizzo}
  //         fieldType="text"
  //         id="indirizzo"
  //         fieldTitle="Indirizzo*"
  //         description="Inserisci l'indirizzo"
  //         max="18"
  //         onChange={onChange}
  //       />
  //       <PopupTextField
  //         defaultValue={data?.credito?.codice_iban}
  //         fieldType="text"
  //         id="codice_iban"
  //         fieldTitle="Codice IBAN*"
  //         description="Inserisci il codice IBAN"
  //         max="34"
  //         min="34"
  //         straightErr={error?.credito?.codice_iban}
  //         onChange={onChange}
  //       />

  //       <PopupTextField
  //         onChange={(e) => { onFileSet2(e, setFiles) }}
  //         fieldType="file"
  //         id="iban"
  //         defaultValue={data?.credito?.iban || ""}
  //         fieldTitle="Documento IBAN*"
  //         description="Allegare il documento IBAN" />

  //     </SummaryCard>
  //   )
  // }

  // const renderExtraContent = () => {
  //   const bonusId = getBonusId();
  //   switch(bonusId){
  //     case "BA3":
  //       return(
  //         <>
  //           <FamilyFormBox id="nucleo_familiare"
  //             cards={data?.nucleo_familiare?.length > 0 ? [data?.nucleo_familiare] : []}
  //             type={false}
  //             description="Informazione sui componenti del tuo nucleo familiare"
  //             title="Nucleo familiare"
  //             service={props.info.code}
  //             update={update}
  //             prefData={props.info.data?.nucleo_familiare || []}
  //           />
  //           <CheckedFormBox
  //             type="checkbox"
  //             title={["Bonus economici", "Dichiaro"]}
  //             description=""
  //             choices={[
  //                 { value: "1-di impegnarsi a comunicare tempestivamente al Comune di Brugnato il venir meno di uno qualunque dei requisiti previsti dal Bando;" },
  //                 { value: "2-di aver preso visione e di accettare quanto stabilito nel Banco di cui all’oggetto e di essere consapevole che: i Buoni Alimentari sono spendibili esclusivamente per l’acquisto di prodotti alimentari per soddisfare le necessità più urgenti ed essenziali presso esercizi commerciali convenzionati; i requisiti dovranno permanere per tutto il tempo di validità della graduatoria, pena la decadenza dalla stessa; ai Cittadini, collocatisi utilmente in graduatoria, verranno corrisposti i Buoni nella misura indicata nel Bando, fino a concorrenza dei fondi stanziati, fatta salva fatta salva la rideterminazione dell’importo sulla base del numero del numero delle istanze ammesse a seguito i controlli effettuati dai preposti uffici; i Cittadini in graduatoria non possono in nessun modo vantare alcun diritto alla corresponsione dei buoni se non nella misura dei fondi stanziati, fatta salva la rideterminazione dell’importo sulla base del numero del numero delle istanze ammesse a seguito i controlli effettuati dai preposti uffici." }
  //             ]}
  //             defaultValue={data?.dichiaro}
  //             update={update}
  //           />

  //           <CheckedFormBox
  //             type="checkbox"
  //             title={["Bonus economici", "Consapevole"]}
  //             description=""
  //             choices={[
  //                 { value: "1-ai sensi dell’art. 71 del DPR. 445/2000 spetta all’Amministrazione comunale procedere ad idonei controlli, anche avvalendosi della collaborazione della Guardia di Finanza, per accertare la veridicità delle dichiarazioni rese e la documentazione presentata dai richiedenti. Ferme restando le sanzioni penali previste dall’art. 76 del D.P.R. n. 445/2000, qualora dal controllo emerga la non veridicità del contenuto delle dichiarazioni rese, il dichiarante decadrà dai benefici conseguiti, fermo restando le denunce alle Autorità competenti. L’Amministrazione comunale agirà per il recupero delle somme indebitamente percepite." },
  //                 { value: "2-ai fini del rispetto delle vigenti disposizioni in materia di privacy (D. Lgs 196/2003) e del Regolamento Europeo N. 679/2016 si rende noto che le INFORMAZIONI SUL TRATTAMENTO DEI DATI PERSONALI, ai sensi dell’artt. 13 e 14 del Regolamento UE 2016/679 sono allegate al presente modulo." }
  //             ]}
  //             defaultValue={data?.consapevole}
  //             update={update}
  //           />
  //         </>
  //       )
  //     case "CAP":
  //       return(
  //         <>
  //           {renderCreditInfo()}
  //           <SummaryCard
  //             type="Costi"
  //             description={"Inserisci i dati"}>
  //               <PopupTextField
  //                 defaultValue={data?.costi?.importo_prima_casa}
  //                 fieldType="text"
  //                 id="importo_prima_casa"
  //                 fieldTitle="Importo acquisto prima casa"
  //                 description="Inserisci l'importo prima casa"
  //                 max="18"
  //                 onChange={(e) => updateField(e, "costi", true)}
  //               />
  //               <PopupTextField
  //                 defaultValue={data?.costi?.importo_interventi}
  //                 fieldType="text"
  //                 id="importo_interventi"
  //                 fieldTitle="Importo interventi di ristrutturazione edilizia prima casa"
  //                 description="Inserisci l'importo degli interventi"
  //                 max="18"
  //                 onChange={(e) => updateField(e, "costi", true)}
  //               />
  //           </SummaryCard>
  //           <SummaryCard
  //             type="Requisiti"
  //             description={"Inserisci i requisiti"}>
  //               <SelectionFormBox
  //                 showTitle={`Residenza`}
  //                 title={"residenza"}
  //                 description={" "}
  //                 choices={values?.requirements?.residence || []}
  //                 defaultValue={data?.requisiti?.residenza}
  //                 classDefaultValue={""}
  //                 oKey={""}
  //                 update={(e) => updateField(e, "requisiti", true) }
  //                 disabled={"  " === "" ? "disabled" : ""} />
  //               <SelectionFormBox
  //                 showTitle={`Tipologia di intervento`}
  //                 title={"tipo_intervento"}
  //                 description={" "}
  //                 choices={values?.requirements?.type || []}
  //                 defaultValue={data?.requisiti?.tipo_intervento}
  //                 classDefaultValue={""}
  //                 oKey={""}
  //                 update={(e) => updateField(e, "requisiti", true) }
  //                 disabled={"  " === "" ? "disabled" : ""} />
  //               <SelectionFormBox
  //                 showTitle={`Nucleo familiare`}
  //                 title={"nucleo_familiare"}
  //                 description={" "}
  //                 choices={values?.requirements?.family || []}
  //                 defaultValue={data?.requisiti?.nucleo_familiare}
  //                 classDefaultValue={""}
  //                 oKey={""}
  //                 update={(e) => updateField(e, "requisiti", true) }
  //                 disabled={"  " === "" ? "disabled" : ""} />
  //               <SelectionFormBox
  //                 showTitle={`Richiedente`}
  //                 title={"richiedente"}
  //                 description={" "}
  //                 choices={values?.requirements?.applicant || []}
  //                 defaultValue={data?.requisiti?.richiedente}
  //                 classDefaultValue={""}
  //                 oKey={""}
  //                 update={(e) => updateField(e, "requisiti", true) }
  //                 disabled={"  " === "" ? "disabled" : ""} />
  //           </SummaryCard>
  //           <SummaryCard type="Marca da bollo*">
  //             <PopupTextField
  //                 defaultValue={data?.marca_bollo?.numero}
  //                 onChange={(e) => { updateField(e, "marca_bollo", true)}}
  //                 id="numero"
  //                 fieldType="text"
  //                 fieldTitle="Numero"
  //                 description=""
  //                 regex="^[0-9]{14}$"
  //                 max="14"
  //                 min="14" />
  //             <PopupTextField
  //                 defaultValue={data?.marca_bollo?.data}
  //                 onChange={(e) => { updateField(e, "marca_bollo", true)}}
  //                 id="data"
  //                 fieldType="date"
  //                 fieldTitle="Data"
  //                 description=""
  //                 min={new Date().toISOString().split('T')[0]}
  //             />
  //           </SummaryCard>
  //           <CheckedFormBox
  //             type="checkbox"
  //             title={["Bonus economici", "Dichiaro"]}
  //             description=""
  //             choices={values?.declarations || []}
  //             defaultValue={data?.dichiaro}
  //             update={update}
  //           />
  //         </>
  //       )
  //     default:
  //       return renderCreditInfo();
  //   }
  // }

  const renderAlerts = () =>  {
    const value = "";
    switch(value) {
      // case "CAP":
      //   return(
      //     <>
      //       <ErrorAlert errors={[{ name: "Richiedente", id: "#richidente" }]} err={error.richiedente || isStopped} />
      //       <ErrorAlert errors={[{ name: "Dati per l'accredito", id: "#credito" }]} err={error.credito} />
      //       <ErrorAlert errors={[{ name: "Costi", id: "#costi" }]} err={error.costi} />
      //       <ErrorAlert errors={[{ name: "Requisiti", id: "#requisiti" }]} err={error.requisiti} />
      //       <ErrorAlert errors={[{ name: "Marca da bollo", id: "#dichiaro" }]} err={error.marca_bollo} />
      //       <ErrorAlert errors={[{ name: "Dichiaro", id: "#dichiaro" }]} err={error.dichiaro} />
      //     </>
      //   )
      default: 
          return(
            <>
              <ErrorAlert errors={[{ name: "Richiedente", id: "#isee-info" }]} err={error.richiedente || isStopped} />
              <ErrorAlert errors={[{ name: "ISEE", id: "#isee-info" }]} err={error.isee} />
              <ErrorAlert errors={[{ name: "Dati per l'accredito", id: "#isee-info" }]} err={error.credito} />
            </>
          )
    }
  }


  const renderContentByType = () => {
    const value = data?.tipologia;
    switch(value){
      case "Imposta Municipale Propria (IMU)":
        return (
          <>
            <SelectionFormBox
              showTitle={`Motivo*`}
              title={"motivo"}
              description={" "}
              choices={values.reasons}
              defaultValue={data?.motivo}
              classDefaultValue={""}
              oKey={""}
              update={update}
              disabled={"  " === "" ? "disabled" : ""} />

          </>
        )
      case "Tassa sui Rifiuti (TARI) - utenze non domestiche":
        return (
          <>
            <SelectionFormBox
              showTitle={`Riduzione per attività stagionali o ad uso non continuativo`}
              title={"riduzione_attivita_stagionali"}
              description={" "}
              choices={values.seasonalReductions}
              defaultValue={data?.riduzione_attivita_stagionali}
              classDefaultValue={""}
              oKey={""}
              update={update}
              disabled={"  " === "" ? "disabled" : ""} />
            <SelectionFormBox
              showTitle={`Riduzione per livelli inferiori di prestazione del servizio`}
              title={"riduzione_livelli_inferiori"}
              description={" "}
              choices={values.lowerLevelsReductions}
              defaultValue={data?.riduzione_livelli_inferiori}
              classDefaultValue={""}
              oKey={""}
              update={update}
              disabled={"  " === "" ? "disabled" : ""} />

            <SelectionFormBox
              showTitle={`Riduzione per produzione di rifiuti non conferibili al pubblico servizio`}
              title={"riduzione_produzione_rifiuti"}
              description={" "}
              choices={values.wasteProductionReductions}
              defaultValue={data?.riduzione_produzione_rifiuti}
              classDefaultValue={""}
              oKey={""}
              update={update}
              disabled={"  " === "" ? "disabled" : ""} />

            <SummaryCard type="Superficie di formazione dei rifiuti o sostanze" description=" ">
              <PopupTextField
                defaultValue={data?.superficie_rifiuti}
                fieldType="text"
                id="superficie_rifiuti"
                fieldTitle="Superficie di formazione dei rifiuti o sostanze"
                description="Aggiungi la superficie alla tua richiesta"
                onChange={(e) => { updateField(e, "superficie_rifiuti", false)}}
              />
            </SummaryCard>
            <SummaryCard type="Uso e tipologie di rifiuti prodotti" description=" ">
              <PopupTextField
                defaultValue={data?.uso_tipologie_rifiuti}
                fieldType="text"
                id="uso_tipologie_rifiuti"
                fieldTitle="Uso e tipologie di rifiuti prodotti"
                description="Aggiungi la superficie alla tua richiesta"
                onChange={(e) => { updateField(e, "uso_tipologie_rifiuti", false)}}
              />
            </SummaryCard>
          </>
        )
      case "Tassa sui Rifiuti (TARI) - utenze domestiche":
        return (
          <>
            <FamilyFormBox id="nucleo_familiare"
              cards={data?.nucleo_familiare?.length > 0 ? [data?.nucleo_familiare] : []}
              type={false}
              description="Informazione sui componenti del tuo nucleo familiare"
              title="Nucleo familiare"
              service={"DAT-family"}
              update={update}
              prefData={props.info.data?.nucleo_familiare || []}
            />
            <SelectionFormBox
              showTitle={`Riduzione `}
              title={"riduzione"}
              description={" "}
              choices={values.reductions}
              defaultValue={data?.riduzione}
              classDefaultValue={""}
              oKey={""}
              update={update}
              disabled={"  " === "" ? "disabled" : ""} />
          </>
        )
      default:
        return null;
    }

  }

  // console.log("specific", isValidStep("specific"));

  return (
    <div className="row">
      <SideList
        infoList={getInfoList()} />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            {renderAlerts()}
            <Loader loading={loading} fixedPosition/>
            <CustomerFormBox id="richiedente"
              cards={data?.richiedente?.length > 0 ? [data?.richiedente] : []}
              type={false}
              description="Informazione su di te"
              title="Richiedente"
              service={props.info.code}
              update={update}
              prefData={props.info.data?.richiedente}
            />

            <SummaryCard type="Anno di riferimento">
              <PopupSelectField
                defaultValue={data?.anno}
                onChange={(e) => update({id: "anno", data: e.target.options[e.target.selectedIndex].text})}
                id={"anno"}
                fieldTitle={"Anno"}
                options={JSON.parse(localStorage.getItem("configuration")).data?.current?.imuYears?.split(",") || []}
                description="Seleziona l'anno di riferimento" />
            </SummaryCard>

            {data?.anno &&
            <CustomerPropertyFormBox
              year={data?.anno}
              id="immobili"
              multiple={false}
              cards={data?.immobili?.dati_comunali ? [[data?.immobili]] : []}
              update={update}
            />}

            {renderContentByType()}
            {/* <IseeFormBox
              id={"isee"}
              description={"Informazioni sul valore dell’ISEE del tuo nucleo familiare basato sulla tua ultima dichiarazione"}
              optionalTitle={false}
              cards={data?.isee?.length > 0 ? data?.isee : []}
              update={update}
              placeholderData={props.info?.data?.isee}
              required={iseeRequired}
            />

            
            {renderExtraContent()} */}

          </div>
        </div>
        <NextBtnForm
          next={onNext}
          back={props.back}
          noSave={false}
          saveRequest={save}
          // disabled={error.richiedente || error.credito || error.isee || error.nucleo_familiare || isStopped} />
          disabled={!isValidStep("specific")} />

        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  )
}


export default Specific;