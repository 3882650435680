import { Service } from "../Service";

const VALUES_BY_TENANT = {
    "default": {
        types: [
            {id: 1, value: "Imposta Municipale Propria (IMU)"},
            {id: 2, value: "Tassa sui Rifiuti (TARI) - utenze non domestiche"},
            {id: 3, value: "Tassa sui Rifiuti (TARI) - utenze domestiche"},
        ],
        reasons: [
            {id: 1, value: "fabbricato inagibile o inabiltabile in situazione di degrado sopravvenuto non superabile con interventi di manutenzione ordinaria o straordinaria"},
            {id: 2, value: "abitazione principale, e relative pertinenze, posseduta da anziani o disabili che acquisiscono la residenza in istituti di ricovero o sanitari a seguito di ricovero permanente"},
            {id: 3, value: "immobili dati in comodato gratuito registrato al Comune o altro ente territoriale, destinati esclusivamente all'esercizio dei rispettivi compiti istituzionali"},
        ],
        seasonalReductions: [
            {id: 1, value: "riduzione del 30% ai locali, diversi dalle abitazioni, ed aree scoperte adibiti ad uso stagionale o ad uso non continuativo, ma ricorrente, purché non superiore a 183 giorni nell’anno solare"},
            // {id: 2, value: ""},
            // {id: 3, value: ""},
        ],
        lowerLevelsReductions: [
            {id: 1, value: "riduzione del 60% se la distanza dell’utenza dal più vicino punto di raccolta rientrante nella zona perimetrata o di fatto servita è inferiore a 1.500 metri lineari;"},
            {id: 2, value: "riduzione del 75% se la distanza dell’utenza dal più vicino punto di raccolta rientrante nella zona perimetrata o di fatto servita è superiore a 1.500 metri lineari e fino a 3.000 metri lineari;"},
            {id: 3, value: "riduzione del 90% se la distanza dell’utenza dal più vicino punto di raccolta rientrante nella zona perimetrata o di fatto servita è superiore a 3.000 metri lineari."},
        ],
        wasteProductionReductions: [
            {id: 1, value: "Lavanderie, tintorie, tipografie e simili"},
            {id: 2, value: "Autocarrozzerie, autofficine e gommisti"},
            {id: 3, value: "Autofficine di elettrauto"},
            {id: 4, value: "Officine di carpenteria metallica"},
            {id: 5, value: "Falegnamerie, mobilifici e simili"},
            {id: 6, value: "Cantine vinicole e caseifici"},
            {id: 7, value: "Altre attività"},
        ],
        reductions: [
            {id: 1, value: "abitazioni tenute a disposizione per uso stagionale od altro uso limitato e discontinuo, non superiore a 183 giorni nell’anno solare: riduzione del 30%;"},
            {id: 2, value: "abitazioni occupate da soggetti che risiedano o abbiano la dimora, per più di sei mesi all'anno, all'estero: riduzione del 30%."}
        ]
    }
}


const serviceId = "TAX_BREAKS" 
const serviceInternalId = "taxBreaks"

export class TaxBreaksService extends Service {
    static SERVICE_ID = serviceId
    static SERVICE_INTERNAL_ID = serviceInternalId
    //////////////////////////////////
    // Contructor
    //////////////////////////////////
    constructor(ctxService) {
        // console.log("ctx service", ctxService)
        const data = {
            ...(ctxService || {}),
        }
        super(VALUES_BY_TENANT, data, ctxService, serviceId, serviceInternalId);
    }

    //////////////////////////////////
    // Generic Methods
    //////////////////////////////////

    getFields() {
        const value = this.data?.tipologia;
        const defaultFieds = ["richiedente", "tipologia", "immobili"]
        switch (value) {
            case "Imposta Municipale Propria (IMU)":
                return [...defaultFieds, "motivo"]
            case "Tassa sui Rifiuti (TARI) - utenze non domestiche":
                return [...defaultFieds, "riduzione_attivita_stagionali", "riduzione_livelli_inferiori", "riduzione_produzione_rifiuti", "superficie_rifiuti", "uso_tipologie_rifiuti"]
            case "Tassa sui Rifiuti (TARI) - utenze domestiche":
                return [...defaultFieds, "nucleo_familiare", "riduzione"]
            default:
                return defaultFieds
        }
    }

    getDefaultPrefs(){
        const value = this.data?.tipologia;
        switch (value) {
            case "Imposta Municipale Propria (IMU)":
                return {
                    tipologia: this.data?.tipologia || "",
                    motivo: this.data?.motivo || "",
                    anno: this.data?.anno || "",
                }
            case "Tassa sui Rifiuti (TARI) - utenze non domestiche":
                return {
                    tipologia: this.data?.tipologia || "",
                    riduzione_attivita_stagionali: this.data?.riduzione_attivita_stagionali || "",
                    riduzione_livelli_inferiori: this.data?.riduzione_livelli_inferiori || "",
                    riduzione_produzione_rifiuti: this.data?.riduzione_produzione_rifiuti || "",
                    superficie_rifiuti: this.data?.superficie_rifiuti || "",
                    uso_tipologie_rifiuti: this.data?.uso_tipologie_rifiuti || "",
                    anno: this.data?.anno || "",
                }
            case "Tassa sui Rifiuti (TARI) - utenze domestiche":
                return {
                    tipologia: this.data?.tipologia || "",
                    nucleo_familiare: this.data?.nucleo_familiare || [],
                    riduzione: this.data?.riduzione || "",
                    anno: this.data?.anno || "",
                }
            default:
                return {
                    tipologia: this.data?.tipologia || "",
                    anno: this.data?.anno || "",
                }
        }
    }

    getPrefs(){
        const value = "";
        switch(value){
            default:
                return super.getPrefs()
        }   
    }
    //////////////////////////////////
    // Field validation
    //////////////////////////////////

    isValidField(id) {
        switch (id) {
            case "tipologia":
                return this.data?.[id] ? true : false
            case "immobili":
                // console.log("/////// immobili", this.data?.[id])
                return (this.data?.[id]?.dati_comunali && this.data?.[id]?.dati_immobile) ? true : false
            default:
                return super.isValidField(id);
        }
    }

    isValidData(isSendRequest){
        const value = "";
        let requiredFields = [];
        // console.log("is valid data", isSendRequest)
        switch(value){
            default:
                if(isSendRequest) requiredFields = this.getFields()
                else requiredFields = []
        }

        // console.log("is valid data",requiredFields)

        if(requiredFields?.length > 0) return this.checkFields(requiredFields)        

        return true;
    }

    //////////////////////////////////
    // Steps validation
    //////////////////////////////////
    isValidStep(stepId){
        this.checkErrors();
        const value = this.data?.tipologia;
        // console.log("is valid step", stepId, value, this.error)
        switch(stepId){
            case "general":
                return (this.error?.tipologia) ? false : true
            case "specific":
            case "summary":
                switch(value){
                    case "Imposta Municipale Propria (IMU)":
                        return (
                            this.error?.richiedente 
                            || this.error?.immobili
                            || this.error?.motivo
                        ) ? false : true
                    case "Tassa sui Rifiuti (TARI) - utenze non domestiche":
                        return (
                            this.error?.richiedente 
                            || this.error?.immobili
                        ) ? false : true
                    case "Tassa sui Rifiuti (TARI) - utenze domestiche":
                        return (
                            this.error?.richiedente 
                            || this.error?.immobili
                        ) ? false : true
                }
                
            default:
                return false;
        }
    }
    
}