import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import FormBoxField from "../../../UI-component/ui-el/FormBoxField";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import stepSlice from "../../../context/StepsContext";
import Loader from "../../../UI-component/Loader";
import { useMarriage } from "./marriage";

function Summary(props) {
    const {data, values, error, loading, update, save, updatePrefs, savePrefs, isValidStep} = useMarriage(props.save, props?.info?.data || {}, props.send);
    const ctxPrefs = useSelector(state => state.stepSlice.data.preferenze);

    const onNext = async () => {
        save(true);
    }

    const applicant1 = data?.richiedente?.[0]
    const applicant2 = data?.richiedente_2?.[0]

    return (

        <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
                <div className="steppers-content" aria-live="polite">
                    <div className="it-page-sections-container">
                        <SummaryCard
                            type="richiedente"
                            cardTitle={`${applicant1?.anagrafica?.nome} ${applicant1?.anagrafica.cognome}`}
                            taxID={applicant1?.anagrafica.codice_fiscale}
                            service={props.info.code}
                            registryData={applicant1}
                            update={update}
                            mod={true}
                        >
                            <FormBoxField title="Nome" value={applicant1?.anagrafica.nome} />
                            <FormBoxField title="Cognome" value={applicant1?.anagrafica.cognome} />
                            <FormBoxField title="Codice Fiscale" value={applicant1?.anagrafica.codice_fiscale} />
                            <FormBoxField title="Data di nascita" value={applicant1?.anagrafica.data_di_nascita} />
                            <FormBoxField title="Luogo di nascita" value={applicant1?.anagrafica.luogo_di_nascita} />
                            <FormBoxField title="Stato civile" value={applicant1?.anagrafica.stato_civile} />
                            <FormBoxField title="Con chi" value={applicant1?.anagrafica.con_chi === " " ? "Non presente" : applicant1.anagrafica.con_chi} />
                            <FormBoxField title="Comune di registrazione dell'atto" value={applicant1?.anagrafica.comune_di_registrazione_atto === " " ? "Non presente" : applicant1.anagrafica.comune_di_registrazione_atto} />
                            <FormBoxField title="Data di registrazione dell'atto" value={applicant1?.anagrafica.data_di_registrazione_atto === " " ? "Non presente" : applicant1.anagrafica.data_di_registrazione_atto} />
                            <FormBoxField title="Indirizzo residenza" value={applicant1?.indirizzi.residenza} />
                            <FormBoxField title="Comune residenza" value={applicant1?.indirizzi.domicilio} />
                            <FormBoxField title="Telefono" value={applicant1?.contatti.telefono} />
                            <FormBoxField title="Email" value={applicant1?.contatti.email} />
                        </SummaryCard>
                        <SummaryCard
                            type="richiedente_2"
                            title="Richiedente 2"
                            cardTitle={`${applicant2?.anagrafica?.nome} ${applicant2?.anagrafica.cognome}`}
                            taxID={applicant2?.anagrafica.codice_fiscale}
                            service={props.info.code}
                            registryData={applicant2}
                            update={update}
                            mod={true}>
                            <FormBoxField title="Nome" value={applicant2?.anagrafica.nome} />
                            <FormBoxField title="Cognome" value={applicant2?.anagrafica.cognome} />
                            <FormBoxField title="Codice Fiscale" value={applicant2?.anagrafica.codice_fiscale} />
                            <FormBoxField title="Data di nascita" value={applicant2?.anagrafica.data_di_nascita} />
                            <FormBoxField title="Luogo di nascita" value={applicant2?.anagrafica.luogo_di_nascita} />
                            <FormBoxField title="Stato civile" value={applicant2?.anagrafica.stato_civile} />
                            <FormBoxField title="Con chi" value={applicant2?.anagrafica.con_chi === " " ? "Non presente" : applicant2.anagrafica.con_chi} />
                            <FormBoxField title="Comune di registrazione dell'atto" value={applicant2?.anagrafica.comune_di_registrazione_atto === " " ? "Non presente" : applicant2.anagrafica.comune_di_registrazione_atto} />
                            <FormBoxField title="Data di registrazione dell'atto" value={applicant2?.anagrafica.data_di_registrazione_atto === " " ? "Non presente" : applicant2.anagrafica.data_di_registrazione_atto} />
                            <FormBoxField title="Indirizzo residenza" value={applicant2?.indirizzi.residenza} />
                            <FormBoxField title="Comune residenza" value={applicant2?.indirizzi.domicilio} />
                            <FormBoxField title="Telefono" value={applicant2?.contatti.telefono} />
                            <FormBoxField title="Email" value={applicant2?.contatti.email} />
                        </SummaryCard>

                        <SummaryCard
                            type="Preferenze"
                            mod={true}
                            cardTitle={`Preferenze di servizio`}
                            service="RPM"
                            registryData={ctxPrefs}
                            choiceData={[
                                {
                                    "options": [
                                        { id: 1, value: "Civile" },
                                        { id: 2, value: "Concordatario" },
                                        { id: 3, value: "Accatolico" },
                                        { id: 4, value: "Altro" }
                                    ],
                                    "title": [
                                        "MATRIMONIO",
                                        "TIPO_MATRIMONIO"
                                    ],
                                    "type": "Select"
                                },
                                {
                                    "options": [
                                        {
                                            "title": "Data matrimonio",
                                            "type": "date"
                                        }
                                    ],
                                    "title": [
                                        "MATRIMONIO",
                                        "DATA MATRIMONIO"
                                    ],
                                    "child_title": [],
                                    "type": "MultiText",
                                    "id": "data_matrimonio"
                                },
                                {
                                    "options": [
                                        {
                                            "id": "numero",
                                            "title": "Numero marca da bollo 1",
                                            "regex": "^[0-9]{14}$",
                                            "max": "14",
                                            "useId": true
                                        },
                                        {
                                            "id": "data",
                                            "title": "Data marca da bollo 1",
                                            "useId": true,
                                            "type": "date"
                                        }
                                    ],
                                    "title": [
                                        "MARRIAGE",
                                        "MARCA BOLLO 1"
                                    ],
                                    "child_title": [],
                                    "type": "MultiText",
                                    "id": "marca_bollo_1"
                                  },
                                  {
                                    "options": [
                                        {
                                            "id": "numero",
                                            "title": "Numero marca da bollo 2",
                                            "regex": "^[0-9]{14}$",
                                            "max": "14",
                                            "useId": true
                                        },
                                        {
                                            "id": "data",
                                            "title": "Data marca da bollo 2",
                                            "useId": true,
                                            "type": "date"
                                        }
                                    ],
                                    "title": [
                                        "MARRIAGE",
                                        "MARCA BOLLO 2"
                                    ],
                                    "child_title": [],
                                    "type": "MultiText",
                                    "id": "marca_bollo_2"
                                  }
                            ]}
                            update={updatePrefs}
                        >

                            <FormBoxField title="Tipo di matrimonio" value={data?.tipo_matrimonio || "Non compilato"} />
                            <FormBoxField title="Data del matrimonio (presumibile)" value={data?.data_matrimonio || "Non compilato"} />
                            <FormBoxField title="Numero marca da bollo 1" value={data?.marca_bollo_1?.numero || "Non compilato"} />
                            <FormBoxField title="Data marca da bollo 1" value={data?.marca_bollo_1?.data || "Non compilato"} />
                            <FormBoxField title="Numero marca da bollo 2" value={data?.marca_bollo_2?.numero || "Non compilato"} />
                            <FormBoxField title="Data marca da bollo 2" value={data?.marca_bollo_2?.data || "Non compilato"} />

                        </SummaryCard>

                        <Loader loading={loading} fixedPosition />
                        <NextBtnForm
                            next={onNext}
                            back={props.back}
                            noSave={false}
                            saveRequest={save}
                        />
                        {
                            props.saved &&
                            <SuccessfullPopup />
                        }

                        {
                            props.fail &&
                            <SuccessfullPopup isError={true} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Summary;