import React from "react";
import { useSelector } from "react-redux";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import FormBoxField from "../../../UI-component/ui-el/FormBoxField";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import { useNavigate } from "react-router-dom";
import { useFee } from "./fee";
import Loader from "../../../UI-component/Loader";

function Summary(props) {
    const {data, values, error: errorHook, loading, update, save, savePrefs, isValidStep} = useFee(props.save, props?.info?.data || {});

    const applicant = data?.richiedente?.[0];
    // const data?.fatture = useSelector(state => state.stepSlice.data.fattura);

    const navigate = useNavigate();

    const onNext = async () => {
        const paymentData = {
            richiedente: data?.richiedente,
            fatture: data?.fatture,
        }

        const res = await props.send(paymentData);

        navigate("/payment-pa", {
            state: {
                data: {
                    ...res.data,
                    title: data?.fatture?.descrizione,
                    code: data?.fatture?.code,
                    iuv: data?.fatture.iuv,
                    email: data?.richiedente?.contatti?.email,
                    headTitle: props.info.headData.title,
                    headDesc: props.info.headData.description
                }
            }
        });
    }

    return (

        <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
                <div className="steppers-content" aria-live="polite">
                    <div className="it-page-sections-container">
                        <SummaryCard
                            type="Effettuato da"
                            cardTitle={`${applicant?.anagrafica?.nome} ${applicant?.anagrafica.cognome}`} >
                            <FormBoxField title="Nome" value={applicant?.anagrafica.nome} />
                            <FormBoxField title="Cognome" value={applicant?.anagrafica.cognome} />
                            <FormBoxField title="Codice Fiscale" value={applicant?.anagrafica.codice_fiscale} />
                            <FormBoxField title="Data di nascita" value={applicant?.anagrafica.data_di_nascita} />
                            <FormBoxField title="Luogo di nascita" value={applicant?.anagrafica.luogo_di_nascita} />
                            <FormBoxField title="Indirizzo residenza" value={applicant?.indirizzi.residenza} />
                            <FormBoxField title="Comune residenza" value={applicant?.indirizzi.domicilio} />
                            <FormBoxField title="Telefono" value={applicant?.contatti.telefono} />
                            <FormBoxField title="Email" value={applicant?.contatti.email} />
                        </SummaryCard>

                        <SummaryCard type="Fattura">
                            <FormBoxField title="Codice IUV" value={data?.fatture?.iuv} />
                            <FormBoxField title="Data emissione" value={data?.fatture?.data_emissione} />
                            <FormBoxField title="Data scadenza" value={data?.fatture?.data_scadenza} />
                            <FormBoxField title="Ente" value={data?.fatture?.ente} />
                            <FormBoxField title="Codice Fiscale ente" value={data?.fatture?.codice_fiscale_ente} />
                            <FormBoxField title="Intestatario" value={data?.fatture?.intestatario} />
                            <FormBoxField title="Codice Fiscale Intestatario" value={data?.fatture?.codice_fiscale_intestatario} />
                            <FormBoxField title="Importo dovuto" value={data?.fatture?.importo_dovuto} />
                            <FormBoxField title="Importo già pagato" value={data?.fatture?.importo_gia_pagato} />
                        </SummaryCard>

                        <NextBtnForm
                            next={onNext}
                            back={props.back}
                            noSave={false}
                            saveRequest={save}
                        />
                        {
                            props.saved &&
                            <SuccessfullPopup />
                        }

                        {
                            props.fail &&
                            <SuccessfullPopup isError={true} />
                        }
                        <Loader loading={loading} fixedPosition/>

                    </div>
                </div>
            </div>
        </div>
    )
}


export default Summary;